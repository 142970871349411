import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Formik, Form } from 'formik';
import { IconButton, TextField, Typography, Button, Stack, Box, FormControl, Select, MenuItem } from '@mui/material';
import Iconify from './Iconify';

const Filter = ({ toggleFilter, applyFilters }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState([null, null]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton onClick={handleClick}>
            <Iconify icon="ic:round-filter-list" />
            <Typography variant="h6"> Filter</Typography>
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Formik
              initialValues={{
                transactionId: '',
                startDate: '',
                endDate: '',
                txnStartDate: '',
                txnEndDate: '',
                status: '',
              }}
              onSubmit={async (values) => {
                applyFilters(values);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Form style={{ padding: '10px' }}>
                  <Stack direction="column">
                    <Stack direction="row" marginBottom="10px" spacing={1}>
                      <TextField
                        fullWidth
                        id="transactionId"
                        name="transactionId"
                        label="Transaction ID"
                        value={values.transactionId}
                        onChange={handleChange}
                      />
                      <TextField
                        select
                        fullWidth
                        id="status"
                        name="status"
                        label="Status"
                        value={values.status}
                        onChange={handleChange}
                      >
                        <MenuItem value="PENDING">PENDING</MenuItem>
                        <MenuItem value="RECEIVED">RECEIVED</MenuItem>
                      </TextField>
                    </Stack>
                    <Stack>
                      <Typography>Bill Date</Typography>
                    </Stack>
                    <Stack direction={'row'} marginBottom="10px" spacing={2}>
                      <TextField
                        type="date"
                        name="startDate"
                        InputLabelProps={{ shrink: true }}
                        value={values.startDate}
                        onChange={handleChange}
                      />
                      <p style={{ paddingTop: '15px' }}>to</p>
                      <TextField
                        type="date"
                        name="endDate"
                        InputLabelProps={{ shrink: true }}
                        value={values.endDate}
                        onChange={handleChange}
                      />
                    </Stack>
                    <Stack>
                      <Typography>Transaction Date</Typography>
                    </Stack>
                    <Stack direction={'row'} spacing={2}>
                      <TextField
                        type="date"
                        name="txnStartDate"
                        InputLabelProps={{ shrink: true }}
                        value={values.txnStartDate}
                        onChange={handleChange}
                      />
                      <p style={{ paddingTop: '15px' }}>to</p>
                      <TextField
                        type="date"
                        name="txnEndDate"
                        InputLabelProps={{ shrink: true }}
                        value={values.txnEndDate}
                        onChange={handleChange}
                      />
                    </Stack>

                    <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
                      Filter
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Menu>
        </Box>
      </div>
    </>
  );
};

export default Filter;
