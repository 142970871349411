
import { createSlice } from '@reduxjs/toolkit';

export const taskMessagesSlice = createSlice({
  name: 'taskMessages',
  initialState: {
    taskMessageNotifications: [],
  },
  reducers: {
    setTaskMessages: (state, action) => { 
      state.taskMessageNotifications = action.payload;
    },
  },
});

export const { setTaskMessages } = taskMessagesSlice.actions;

export default taskMessagesSlice.reducer;
