import {
  Autocomplete,
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Pagination,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';
import moment from 'moment';
import axios from 'axios';
import { Add, CloseRounded } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import SubTaskCard from './SubTaskCard';

function AddSubTask({ open, handleClose, handleSuccess, taskId, members, projectId }) {
  const updatedMembers = members.map((member) => ({
    _id: member.userId._id,
    fullname: member.userId.fullname,
    role: member.role,
    profilePic: member.userId.profilePic,
  }));

  const subTaskSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    userId: Yup.string().required('User is required'),
    checkListCommaSeperated: Yup.string().required('Checklist is required'),
  });

  const formik = useFormik({
    initialValues: {
      taskId,
      projectId,
      userId: '',
      name: '',
      description: '',
      checkListCommaSeperated: ' ',
      dueDate: moment().format('YYYY-MM-DD'),
    },
    validationSchema: subTaskSchema,
    onSubmit: async () => {
      const checkList = [
        ...new Set(
          values.checkListCommaSeperated
            .split(',')
            .map((item) => item.trim())
            .filter((item) => item !== '')
        ),
      ]; 
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/subtask/manager`, { ...values, checkList });
        toast(res.data.message);
        resetForm();
        handleSuccess();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Stack direction="row" justifyContent={'space-between'} marginX={-1}>
          Add Sub Task
          <Tooltip TransitionComponent={Zoom} title="Close">
            <CloseRounded onClick={handleClose} sx={{ color: 'black', cursor: 'pointer' }} />
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container mt={1} spacing={1.5}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  id="combo-box-demo"
                  options={updatedMembers}
                  getOptionLabel={(option) => option.fullname}
                  sx={{ width: '100%' }}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('userId', newValue._id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="User"
                      error={Boolean(touched.userId && errors.userId)}
                      helperText={touched.userId && errors.userId}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Avatar sx={{ width: 40, height: 40, cursor: 'pointer' }} src={option?.profilePic}>
                          {option.fullname.charAt(0).toUpperCase()}
                        </Avatar>
                        <Stack direction={'column'}>
                          <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                            {option.fullname}
                          </Typography>
                          <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                            {option.email}
                          </Typography>
                        </Stack>
                      </Stack>
                    </li>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Due Date"
                  type="date"
                  {...getFieldProps('dueDate')}
                  error={Boolean(touched.dueDate && errors.dueDate)}
                  helperText={touched.dueDate && errors.dueDate}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  {...getFieldProps('description')}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Checklist (comma separated)"
                  {...getFieldProps('checkListCommaSeperated')}
                  error={Boolean(touched.checkListCommaSeperated && errors.checkListCommaSeperated)}
                  helperText={touched.checkListCommaSeperated && errors.checkListCommaSeperated}
                />
              </Grid>

              <Grid item xs={12}>
                <Stack direction={'row'} justifyContent="flex-end">
                  <LoadingButton
                    loading={isSubmitting}
                    variant="contained"
                    type="submit"
                    sx={{ width: '100px' }}
                    color="buttonone"
                  >
                    Add
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}

function SubTasks({ members, taskId, projectId, manager }) {
  const [isLoading, setIsLoading] = useState(true);
  const [subTaskCount, setSubTaskCount] = useState(0);
  const [subTasks, setSubTasks] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(4);
  const [options, setOptions] = useState({ page, size, taskId, projectId });
  const [open, setOpen] = useState(false);

  const getSubTasks = async (options) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/subtask/${manager ? 'manager' : 'user'}/all`, {
        params: options,
      });
      console.log(res);
      setIsLoading(false);
      if (!res.data.isError) {
        setSubTasks(res.data.data.records);
        setSubTaskCount(res.data.data.maxRecords);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (event, newPage) => {
    console.log(newPage);
    setPage(newPage - 1);
    const temp = { ...options, page: newPage - 1 };
    setOptions(temp);
    getSubTasks(temp);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuccess = () => {
    setOpen(false);
    getSubTasks(options);
  };

  useEffect(() => {
    getSubTasks(options);
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Grid container spacing={3}>
          <AddSubTask
            open={open}
            handleClose={handleClose}
            handleSuccess={handleSuccess}
            taskId={taskId}
            projectId={projectId}
            members={members}
          />
          <Grid item xs={12}>
            <Stack sx={{ width: '100%' }} direction="row" justifyContent={'space-between'} alignItems={'center'}>
              <Typography
                sx={{
                  color: 'rgba(46, 46, 46, 0.80)',
                  fontFamily: 'Sora',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: 'normal',
                  letterSpacing: '-0.08px',
                }}
              >
                Sub Tasks
              </Typography>
              {manager && (
                <Button
                  startIcon={<Add />}
                  size="small"
                  variant="contained"
                  sx={{ width: '80px' }}
                  color="buttonone"
                  onClick={() => setOpen(true)}
                >
                  Add
                </Button>
              )}
            </Stack>
          </Grid>
          {subTasks.map((subTask, index) => (
            <Grid key={index} item xs={12} sm={6}>
              <SubTaskCard subTask={subTask} />
            </Grid>
          ))}
          <Grid item xs={12} alignItems={'center'} justifyContent={'center'} display={'flex'}>
            <Pagination count={Math.ceil(subTaskCount / size)} page={page + 1} onChange={handlePageChange} />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default SubTasks;
