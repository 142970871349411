import moment from 'moment';
import { Formik, Form } from 'formik';
import { TextField, Button, Stack, Box, MenuItem, Collapse, Grid, Autocomplete } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';

const Filter = ({ open, filterValues, applyFilters }) => {
  const [categories, setCategories] = useState([]);

  const getCategories = () => {
    try {
      axios.get(`${process.env.REACT_APP_API_URL}/category/user`).then((res) => {
        setCategories(res.data.data.categories);
        console.log('All CATEGORIES', res.data.data.categories);
      });
    } catch (err) {
      console.log(err);
    } 
  };
 
  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Collapse in={open}>
      <Formik
        initialValues={{
          name: filterValues.name || '',
          startDate: filterValues.startDate ? moment(filterValues.startDate).format('YYYY-MM-DD') : '',
          endDate: filterValues.endDate ? moment(filterValues.endDate).format('YYYY-MM-DD') : '',
          category: filterValues.category || 'ALL',
          location: filterValues.location || '',
          userId: filterValues.userId || '',
        }}
        onSubmit={async (values) => {
          applyFilters(values);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Box
            sx={{
              borderRadius: '6px',
              padding: 2,
              cursor: 'pointer',
              backgroundColor: '#fff',
              width: '100%',
            }}
          >
            <Form style={{ padding: '10px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Project Name"
                    value={values.name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    multiple={false}
                    id="multiple-checkbox"
                    value={categories.find((category) => category.name === values.category) || null}
                    options={categories}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => {
                      if (value) {
                        values.category = value.name;
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Category"
                        onChange={handleChange}
                        error={Boolean(touched.category && errors.category)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    id="location"
                    name="location"
                    label="Location"
                    value={values.location}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    id="userId"
                    name="userId"
                    label="User ID"
                    value={values.userId}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Stack direction={'row'} spacing={2}>
                    <TextField
                      type="date"
                      name="startDate"
                      label="Start Date"
                      InputLabelProps={{ shrink: true }}
                      value={values.startDate}
                      onChange={handleChange}
                    />
                    <p style={{ paddingTop: '15px' }}>to</p>
                    <TextField
                      type="date"
                      name="endDate"
                      label="End Date"
                      InputLabelProps={{ shrink: true }}
                      value={values.endDate}
                      onChange={handleChange}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Button variant="contained" type="submit" style={{ marginTop: '10px' }} color="buttonone">
                    Apply
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Box>
        )}
      </Formik>
    </Collapse>
  );
};

export default Filter;
