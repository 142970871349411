import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box } from '@mui/material';
// components
import { BaseOptionChart } from '../components/chart';

// ----------------------------------------------------------------------

BarAndLineGraph.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function BarAndLineGraph({ title, chartLabels, chartData, ...other }) {
  const numDataPoints = chartLabels.length;
  const maximumBarWidth = 40; // Adjust this value according to your needs
  const barWidth = numDataPoints > 0 ? Math.min(40 / numDataPoints, maximumBarWidth) : maximumBarWidth;

  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: { bar: { distributed: true, columnWidth: '10%' } },
    xaxis: { 
      type: 'text',
      categories: chartLabels,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)}`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card {...other} sx={{ boxShadow: 'none' }}>
      <CardHeader title={title} />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="bar" series={[chartData]} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
