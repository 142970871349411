
import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;


export const getTaskMessages = async () => {
  try {
    const response = await axios.get(`${baseURL}/chat/user/unread/tasks`);
    return response.data.data.tasks || [];
  } catch (error) {
    console.error('Error fetching task messages:', error);
    throw error; 
  }
};

