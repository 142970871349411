import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Tooltip,
  Zoom,
  TablePagination,
  TableHead,
  CircularProgress,
  Grid,
  TextField,
} from '@mui/material';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import CopyToClipboard from 'react-copy-to-clipboard';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import Scrollbar from '../components/Scrollbar';
import Page from '../components/Page';
import Filter from '../components/TransactionsFilter';
import Iconify from '../components/Iconify';

export default function Billing() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [transactionData, setTransactionData] = useState();
  const [pages, setPages] = useState(0);
  const [transactionCount, setTransactionCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const size = 10;
  const [options, setOptions] = useState({ size, page: 0, projectId: id });
  const [estimateAmount, setEstimateAmount] = useState(0);
  const [projectName, setProjectName] = useState('');
  const [totalReceived, setTotalReceived] = useState(0);

  const getTransactions = async (options) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/transaction/project/client/all`, {
        params: options,
      });
      console.log('res', res);
      setTransactionData(res.data.data.data.transactions);
      setTransactionCount(res.data.data.data.maxRecords);
      setTotalReceived(res.data.data.data.totalReceived);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getEstimate = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/estimate/client`, {
        params: { projectId: id },
      });
      console.log('estimate', res);
      setEstimateAmount(res.data.data.data.estimatedAmount);
      setProjectName(res.data.data.data.name);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    const temp = { ...options, page: newPage };
    setOptions(temp);
    getTransactions(temp);
  };

  const getAttachment = async (transactionId) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/transaction/attachment/client`, {
        params: { id: transactionId, projectId: id },
      });
      window.open(res.data.data.fileURL, '_blank');
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };
  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size, projectId: id };

    if (filter.transactionId.trim()) {
      temp.transactionId = filter.transactionId.trim();
    }
    if (filter.status.length) {
      temp.status = filter.status;
    }
    if (filter.startDate.length) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate.length) {
      temp.endDate = filter.endDate;
    }
    if (filter.txnStartDate.length) {
      temp.txnStartDate = filter.txnStartDate;
    }
    if (filter.txnEndDate.length) {
      temp.txnEndDate = filter.txnEndDate;
    }
    setOptions(temp);
    setPages(0);
    getTransactions(temp);
  };

  useEffect(() => {
    getTransactions(options);
    getEstimate();
  }, []);

  return (
    <Page title="Dashboard : view_project ">
      <Container>
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Grid>
            <Card
              sx={{
                bgcolor: '#ded3d3',
                marginBottom: '10px',
              }}
            >
              <Stack sx={{ width: '100%' }} paddingX={1} paddingTop={1} justifyContent="flex-end" direction="row">
                <CloseRoundedIcon sx={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
              </Stack>
              <Stack sx={{ width: '100%', marginBottom: '20px', marginTop: '-10px' }}>
                <Typography variant="h4" textAlign={'center'}>
                  {projectName}
                </Typography>
              </Stack>
            </Card>

            <Card sx={{ padding: '5px' }}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'space-between' },
                  margin: '5px',
                  alignItems: { xs: 'flex-end', sm: 'center' },
                }}
              >
                <TextField size="small" label="Estimated Amount" value={estimateAmount ? `₹ ${estimateAmount}` : 0} />
                <TextField value={`₹ ${totalReceived}`} size="small" label="Total Received" />
                <Filter applyFilters={applyFilters} />
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    {/* <UserListHead /> */}
                    <TableHead>
                      <TableRow>
                        <TableCell>Sl No</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Transaction ID</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Remark</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Recieved On</TableCell>
                        <TableCell>File</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transactionData?.map((item, id) => (
                        <TableRow key={id}>
                          <TableCell>{pages * size + (id + 1)}</TableCell>
                          <TableCell>{Moment(item.date).format('MMM DD, YYYY')}</TableCell>
                          <CopyToClipboard text={item.transactionId} onCopy={() => toast.info('Transaction ID copied')}>
                            <TableCell style={{ cursor: 'pointer' }}>{item.transactionId}</TableCell>
                          </CopyToClipboard>
                          <TableCell>{`₹${item.amount}`}</TableCell>
                          <TableCell>{item.remark}</TableCell>
                          <TableCell>{item.status}</TableCell>
                          <TableCell>{item.receivedDate && Moment(item.receivedDate).format('MMM DD, YYYY')}</TableCell>
                          <TableCell>
                            {item.attachment && (
                              <>
                                <Iconify
                                  sx={{ fontSize: '24px', cursor: 'pointer' }}
                                  icon="carbon:document-view"
                                  onClick={() => getAttachment(item._id)}
                                />
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[]}
                component={'div'}
                count={transactionCount}
                onPageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                page={pages}
                showFirstButton
                showLastButton
              />
            </Card>
          </Grid>
        )}
      </Container>
    </Page>
  );
}
