import { useState } from 'react';
import Calendar from 'react-calendar';
import './CalendarCard';
import { Box } from '@mui/material';

function MemberCalendarCard() {
  const [value, onChange] = useState(new Date());

  return (
    <Box
      sx={{
        width: '100%',
        background: '#fff',
        borderRadius: '12px', 
        padding: 1,
        height: '100%',
        display: 'flex', 
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className='member-calendar-card'
    >
      <Calendar onChange={onChange} value={value} />
    </Box>
  );
}

export default MemberCalendarCard;
