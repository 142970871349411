import { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation, useParams } from 'react-router-dom';
import Moment from 'moment';
import axios from 'axios';

// material
import {
  Container,
  Stack,
  Typography,
  CircularProgress,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Avatar,
  FormControl,
  Autocomplete,
  TextField,
  Button,
  Tooltip,
  Zoom,
  useMediaQuery,
  Menu,
  MenuItem,
  CardMedia,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, useFormik, FormikProvider } from 'formik';
import { toast } from 'react-toastify';

import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Edit, InsertInvitation, MoreVert, PieChart, Receipt, ReceiptLong, Today } from '@mui/icons-material';
import CategoryIcon from '@mui/icons-material/Category';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { ListTask } from '../components/ListTask';

// components
import Page from '../components/Page';
import BreadCrumps from '../components/BreadCrumps';
import Iconify from '../components/Iconify';

// ----------------------------------------------------------------------

const headingStyle = {
  color: '#2E2E2E',
  fontFamily: 'Sora',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  letterSpacing: '-0.09px',
};

const contentStyle = {
  color: '#2E2E2E',
  fontFamily: 'Sora',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  letterSpacing: '-0.08px',
};

const memberHeadingStyle = {
  color: 'rgba(46, 46, 46, 0.80)',
  fontFamily: 'Sora',
  fontSize: '16px',
  fontWeight: 600,
  letterSpacing: '-0.08px',
};

export default function ViewProject() {
  const navigate = useNavigate();
  const { id } = useParams();
  const accType = JSON.parse(localStorage.getItem('profile')).accType;
  const [project, setProject] = useState([]);
  const [nonmembers, setNonmembers] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [pages, setPages] = useState(0);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')).fullname);
  const [manager, setManager] = useState(false);
  const [projectCheck, setProjectCheck] = useState(0);
  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [description, setDescription] = useState('');
  const [open2, setOpen2] = useState({ flag: false, id: null });
  const [editMode, setEditMode] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [category, setCategory] = useState('');
  const [location, setLocation] = useState('');
  const [projectImage, setProjectImage] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [updateProjectLoading, setUpdateProjectLoading] = useState(false);

  const matches = useMediaQuery('(max-width:900px)');

  const formik = useFormik({
    initialValues: {
      userIds: [],
      projectId: id,
    },

    onSubmit: async () => {
      console.log('values', values);
      try {
        await axios
          .put(`${process.env.REACT_APP_API_URL}/project/manager/add-members`, values)
          .then((res) => {
            toast.success(res.data.message);
            handleClose();
            callApis();
          })
          .catch((err) => {
            toast.error(err.response.data.message);
            console.log(err);
          });
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  });

  const handleClick = () => {
    setOpen(!open);
  };

  const getProject = async () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/project/user`, {
        params: { id },
      })
      .then((res) => {
        console.log('project', res);
        if (!res.data.isError) {
          setProject(res.data.data.project);
          setDescription(res.data.data.project.description);
          setStartDate(res.data.data.project.startDate);
          setEndDate(res.data.data.project.endDate);
          setCategory(res.data.data.project.category);
          setLocation(res.data.data.project.location);
          if (res.data.data.project.manager.fullname === user) {
            setManager(true);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getNonmembers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/manager/nonmembers`, {
        params: { projectId: id },
      });
      console.log('nonMembersss', res);
      setNonmembers(res.data.data.data.nonmembers);
    } catch (error) {
      console.log(error);
    }
  };

  const removeMember = async (userId) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/project/manager/remove-member`, {
        userId: open2.id,
        projectId: id,
      });
      toast.success(res.data.message);
      setOpen2({ flag: false, id: null });
      callApis();
    } catch (error) {
      console.log(error);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const updateProject = async () => {
    setUpdateProjectLoading(true);
    const data = {
      projectId: id,
      description,
      startDate,
      endDate,
      category,
      location,
    };
    if (projectImage) {
      data.projectImage = projectImage;
    }
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/project/manager/edit`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Project  Successfully Updated');
      getProject();
      setIsLoading(false);
      setUpdateProjectLoading(false);
      setEditMode(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = (e, flag = '') => {
    setOpen1(false);
    setOpen2(false);
    // setOpen3(false);
    if (flag) {
      callApis();
    }
  };
  const handleDialog = () => {
    setOpen1(true);
  };
  const handleDeletePopup = (id) => {
    setOpen2({ flag: true, id });
  };

  const callApis = async () => {
    setIsLoading(true);
    await getProject();
    if (manager) {
      await getNonmembers();
    }
  };

  useEffect(() => {
    callApis();
  }, []);
  useEffect(() => {
    if (manager) {
      getNonmembers();
    }
  }, [manager]);
  const { errors, touched, handleSubmit, handleChange, setFieldValue, values, getFieldProps } = formik;

  return (
    <Page title="Dashboard: Project">
      <Container maxWidth="xl">
        {isloading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Dialog
              open={open1}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <Stack direction="row" justifyContent={'space-between'} marginX={-1}>
                  {'Add Members'}
                  <Tooltip TransitionComponent={Zoom} title="Close">
                    <CloseRoundedIcon onClick={handleClose} sx={{ color: 'black', cursor: 'pointer' }} />
                  </Tooltip>
                </Stack>
              </DialogTitle>
              <DialogContent>
                <FormikProvider value={formik}>
                  <Form onSubmit={handleSubmit}>
                    <Stack direction="column">
                      <FormControl sx={{ m: 1, width: { sm: '350px', xs: '250px' } }}>
                        <Autocomplete
                          multiple
                          id="multiple-limit-tags"
                          name="userIds"
                          options={nonmembers}
                          getOptionLabel={(option) => option.fullname}
                          onChange={(e, value) => {
                            const temp = value.map((item, index) => item._id);
                            setFieldValue('userIds', temp);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select members"
                              placeholder="Members"
                              fullWidth
                              onChange={handleChange}
                              error={Boolean(touched.userIds && errors.userIds)}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Stack direction="row" spacing={2} alignItems="center">
                                <Avatar sx={{ width: 40, height: 40, cursor: 'pointer' }} src={option?.profilePic}>
                                  {option.fullname.charAt(0).toUpperCase()}
                                </Avatar>
                                <Stack direction={'column'}>
                                  <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                    {option.fullname}
                                  </Typography>
                                  <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                    {option.email}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </li>
                          )}
                        />
                      </FormControl>
                      <Stack mt={2} padding={1} spacing={2} direction="row" justifyContent="center">
                        <Button variant="contained" type="submit" color="buttonone">
                          Add
                        </Button>
                      </Stack>
                    </Stack>
                  </Form>
                </FormikProvider>
              </DialogContent>
            </Dialog>
            <Dialog
              open={open2.flag}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{'Remove member'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to remove this member from Project?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button  onClick={handleClose}>
                  No
                </Button>
                <Button onClick={removeMember} variant='contained' color="buttonone" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
           
            <Grid container spacing={5} rowGap={3}>
              <Grid item xs={12}>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  spacing={2}
                  sx={{
                    marginBottom: '-30px',
                  }}
                >
                  <BreadCrumps
                    project={{
                      name: project?.name,
                      id: project?._id,
                    }}
                  />

                  <Stack direction={'row'} alignItems={'center'} spacing={2}>
                    {manager && (
                      <Button
                        startIcon={<PieChart />}
                        variant="outlined"
                        color="buttonone"
                        onClick={() => navigate(`/dashboard/project-analytics/${id}`)}
                        sx={{
                          borderWidth: '2px',
                          ':hover': {
                            borderWidth: '2px',
                          },
                          fontFamily: 'Sora',
                        }}
                      >
                        Analytics
                      </Button>
                    )}
                    {accType === 'CLIENT' && (
                      <Button
                        startIcon={<ReceiptLong />}
                        variant="contained"
                        color="buttonone"
                        onClick={() => navigate(`/dashboard/billing/${id}`)}
                        sx={{ fontFamily: 'Sora' }}
                      >
                        Billing
                      </Button>
                    )}
                  </Stack>
                </Stack>
              </Grid>
              <Grid item lg={7} md={7} sm={12} xs={12}>
                <Box
                  sx={{
                    bgcolor: '#fff',
                    minHeight: '520px',
                    borderRadius: '12px',
                    height: '100%',
                  }}
                  padding={4}
                >
                  <Stack direction="column" sx={{ width: '100%' }} spacing={4}>
                    <Stack direction="row" justifyContent="space-between" alignItems="start" sx={{ width: '100%' }}>
                      <Stack direction="row" spacing={0.5}>
                        <Today sx={{ color: '#63A3C0' }} />
                        <Stack direction="column" justifyContent={'start'} spacing={0.5}>
                          <Typography sx={headingStyle}>Start Date</Typography>
                          {!editMode ? (
                            <Typography sx={contentStyle}>
                              {project?.startDate ? Moment(project?.startDate).format('YYYY-MM-DD') : ' '}
                            </Typography>
                          ) : (
                            <TextField
                              sx={{ width: '145px' }}
                              type="date"
                              size="small"
                              onChange={(e) => setStartDate(e.target.value)}
                              InputLabelProps={{ shrink: true }}
                              value={startDate ? Moment(startDate).format('YYYY-MM-DD') : ''}
                            />
                          )}
                        </Stack>
                      </Stack>
                      <Stack direction="row" spacing={5}>
                        <Stack direction="row" spacing={0.5}>
                          <InsertInvitation sx={{ color: '#63A3C0' }} />
                          <Stack direction="column" justifyContent={'start'} spacing={0.5}>
                            <Typography sx={headingStyle}>End Date</Typography>
                            {!editMode ? (
                              <Typography sx={contentStyle}>
                                {project?.endDate ? Moment(project?.endDate).format('YYYY-MM-DD') : ' '}
                              </Typography>
                            ) : (
                              <TextField
                                sx={{ width: '145px' }}
                                type="date"
                                size="small"
                                onChange={(e) => setEndDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                value={endDate ? Moment(endDate).format('YYYY-MM-DD') : ''}
                              />
                            )}
                          </Stack>
                        </Stack>
                        {manager && (
                          <MoreVert
                            sx={{
                              color: '#1C1B1F',
                              marginLeft: 'auto',
                              cursor: 'pointer',
                            }}
                            onClick={handleMenuOpen}
                          />
                        )}
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={() => setAnchorEl(null)}
                        >
                          <MenuItem
                            onClick={() => {
                              setEditMode(true);
                              setAnchorEl(null);
                            }}
                          >
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Edit />
                              <Typography sx={contentStyle}>Edit</Typography>
                            </Stack>
                          </MenuItem>
                        </Menu>
                      </Stack>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="start" sx={{ width: '100%' }}>
                      <Stack direction="row" spacing={0.5}>
                        <CategoryIcon
                          sx={{
                            color: '#63A3C0',
                          }}
                        />
                        <Stack direction="column" justifyContent={'start'} spacing={0.5}>
                          <Typography sx={headingStyle}>Category</Typography>
                          {!editMode ? (
                            <Typography sx={contentStyle}>{project.category}</Typography>
                          ) : (
                            <TextField
                              select
                              size="small"
                              name="category"
                              autowidth
                              value={category}
                              onChange={(e) => setCategory(e.target.value)}
                            >
                              <MenuItem value="COMMERCIAL">Commercial</MenuItem>
                              <MenuItem value="COMMERCIAL_INTERIOR">Commercial Interior</MenuItem>
                              <MenuItem value="CULTURAL">Cultural</MenuItem>
                              <MenuItem value="EDUCATIONAL">Educational</MenuItem>
                              <MenuItem value="HOSPITALITY">Hospitality</MenuItem>
                              <MenuItem value="RESIDENTIAL">Residential</MenuItem>
                            </TextField>
                          )}
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="start" sx={{ width: '100%' }}>
                      <Stack direction="row" spacing={0.5}>
                        <LocationOnIcon sx={{ color: '#63A3C0' }} />
                        <Stack direction="column" justifyContent={'start'} spacing={0.5}>
                          <Typography sx={headingStyle}>Location</Typography>
                          {!editMode ? (
                            <Typography sx={contentStyle}>{project.location}</Typography>
                          ) : (
                            <TextField
                              size="small"
                              onChange={(e) => setLocation(e.target.value)}
                              InputLabelProps={{ shrink: true }}
                              value={location}
                            />
                          )}
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack direction="column" sx={{ width: '100%' }} spacing={2} pt={1}>
                      <Typography sx={{ ...headingStyle, fontSize: '16px' }}>Description</Typography>
                      {!editMode ? (
                        <Box
                          sx={{
                            width: '100%',
                            borderRadius: '6px',
                            // background: '#F5F5F5',
                          }}
                          padding={1.5}
                        >
                          <Typography
                            sx={{
                              color: '#414141',
                              fontFamily: 'Work Sans',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              letterSpacing: '-0.014px',
                            }}
                          >
                            {project.description}
                          </Typography>
                        </Box>
                      ) : (
                        <TextField
                          multiline
                          rows={5}
                          maxRows={10}
                          size="small"
                          fullWidth
                          type="text"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      )}
                    </Stack>
                    {!editMode && project?.projectImage && (
                      <Stack direction="column" sx={{ width: '100%' }} spacing={2}>
                        <CardMedia component="img" image={project.projectImage} alt="project image" />
                      </Stack>
                    )}
                    {editMode && (
                      <TextField
                        type="file"
                        fullWidth
                        size="small"
                        variant="outlined"
                        label="Project Image"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setProjectImage(e.target.files[0])}
                      />
                    )}
                    {editMode && (
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        sx={{ width: '100%' }}
                        spacing={2}
                      >
                        <Button
                          variant="outlined"
                          color="buttonone"
                          onClick={() => setEditMode(false)}
                          sx={{
                            borderWidth: '2px',
                            ':hover': {
                              borderWidth: '2px',
                            },
                            fontFamily: 'Sora',
                          }}
                        >
                          Cancel
                        </Button>
                        <LoadingButton
                          variant="contained"
                          loading={updateProjectLoading}
                          color="buttonone"
                          onClick={updateProject}
                          sx={{
                            borderWidth: '2px',
                            ':hover': {
                              borderWidth: '2px',
                            },
                            fontFamily: 'Sora',
                          }}
                        >
                          Update
                        </LoadingButton>
                      </Stack>
                    )}
                  </Stack>
                </Box>
              </Grid>
              <Grid item lg={5} md={5} sm={12} xs={12}>
                <Box
                  sx={{
                    bgcolor: '#fff',
                    borderRadius: '12px',
                  }}
                  padding={4}
                >
                  <Stack direction="column" spacing={4} sx={{ width: '100%' }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="start" sx={{ width: '100%' }}>
                      <Typography sx={{ ...headingStyle, color: '#0096DB' }}>Project Team</Typography>
                    </Stack>
                    <Stack direction="column" spacing={1.5} sx={{ width: '100%' }}>
                      <Typography sx={memberHeadingStyle}>Manager</Typography>
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/dashboard/member/${project?.manager._id}`)}
                      >
                        <Tooltip TransitionComponent={Zoom} title={project?.manager.email}>
                          <Avatar alt={project?.manager.fullname} src={project?.manager?.profilePic}>
                            {project?.manager?.fullname[0]}
                          </Avatar>
                        </Tooltip>
                        <Typography sx={contentStyle}>{project?.manager.fullname}</Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="column" spacing={1.5} sx={{ width: '100%' }}>
                      <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        sx={{ width: '100%' }}
                      >
                        <Typography sx={memberHeadingStyle}>Members</Typography>
                        {manager && (
                          <Button
                            color="buttonone"
                            startIcon={<AddCircleOutlineSharpIcon />}
                            sx={{ fontFamily: 'Sora' }}
                            onClick={handleDialog}
                          >
                            Add
                          </Button>
                        )}
                      </Stack>
                      <Stack
                        direction="column"
                        spacing={1.5}
                        sx={{ width: '100%', maxHeight: '250px', overflowY: 'scroll' }}
                        className="scrollCard"
                      >
                        {project?.members.map((mdata, id) => (
                          <Stack
                            direction={'row'}
                            key={id}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            sx={{
                              width: '100%',
                              background: '#fff',
                              ':hover': {
                                background: '#f8f8f8',
                              },
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                              sx={{ cursor: 'pointer' }}
                              onClick={() => navigate(`/dashboard/member/${mdata._id}`)}
                            >
                              {/* <Avatar alt={mdata.fullname} src={mdata.avatar} /> */}
                              <Tooltip TransitionComponent={Zoom} title={mdata.email}>
                                <Avatar alt={mdata.fullname} src={mdata.profilePic}>
                                  {mdata.fullname.charAt(0).toUpperCase()}
                                </Avatar>
                              </Tooltip>
                              <Typography sx={contentStyle}>{mdata.fullname}</Typography>
                            </Stack>
                            {manager && (
                              <IconButton onClick={() => handleDeletePopup(mdata._id)} color="buttonone">
                                <Iconify icon="material-symbols:delete" />
                              </IconButton>
                            )}
                          </Stack>
                        ))}
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={3} marginTop={4}>
              <Grid item xs={12}>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ width: '100%' }}>
                  <Typography
                    sx={{
                      color: '#0B132B',
                      fontFamily: 'Sora',
                      fontSize: '22px',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: 'normal',
                      letterSpacing: '-0.11px',
                    }}
                  >
                    Tasks
                  </Typography>
                  {manager && (
                    <Button
                      sx={{ fontFamily: 'Sora' }}
                      variant="contained"
                      startIcon={<AddCircleOutlineSharpIcon />}
                      onClick={() => navigate(`/dashboard/project/create/${id}`)}
                      color="buttonone"
                    >
                      Task
                    </Button>
                  )}
                </Stack>
              </Grid>

              <ListTask />
            </Grid>
          </>
        )}
      </Container>
    </Page>
  );
}
