import { ACTION_TYPES } from './ChatActionTypes';

export const INITIAL_STATE = {
  loading: true,
  printMessages: [],
  error: false,
};

export const chatReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_FIRST_START:
      return {
        ...state,
        loading: true,
        printMessages: [],
        error: false,
      };
    case ACTION_TYPES.FETCH_FIRST_SUCCESS:
      return {
        ...state,
        loading: false,
        printMessages: action.payload,
        error: false,
      };
    case ACTION_TYPES.FETCH_FIRST_ERROR:
      return {
        ...state,
        loading: false,
        printMessages: [],
        error: false,
      };
    case ACTION_TYPES.FETCH_MORE_START:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case ACTION_TYPES.FETCH_MORE_SUCCESS:
      return {
        ...state,
        loading: false,
        printMessages: [...state.printMessages, ...action.payload],
        error: false,
      };
    case ACTION_TYPES.FETCH_MORE_ERROR:
      return {
        ...state,
        loading: false,
        printMessages: [...state.printMessages],
        error: true,
      };
    case ACTION_TYPES.FETCH_NEW_START:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case ACTION_TYPES.FETCH_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        printMessages: [...action.payload],
        error: false,
      };
    case ACTION_TYPES.FETCH_NEW_ERROR:
      return {
        ...state,
        loading: false,
        printMessages: [...state.printMessages],
        error: true,
      };
    case ACTION_TYPES.VIEW_NEW_MESSAGES:
      return {
        ...state,
      };
    default:
      return state;
  }
};
