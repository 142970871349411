import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Avatar,
  Badge,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Container,
  Divider,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  Zoom,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ProgressBar from '@ramonak/react-progress-bar';
import PieChartCount from '../graphs/PieChartCount';
import PieChartProjectStorage from '../graphs/PieChartProjectStorage';
import Page from '../components/Page';

export default function CategoryAnalytics() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  console.log('searchParams', searchParams);
  const category = searchParams.get('category');
  const id = searchParams.get('id');
  const matches = useMediaQuery('(max-width:1419px)');
  const [taskCompleted, setTaskCompleted] = useState(0);
  const [taskCountsGraphData, setTaskCountsGraphData] = useState([]);
  const [storageUsage, setStorageUsage] = useState([]);
  const [storageFormat, setStorageFormat] = useState('');
  const [taskList, setTaskList] = useState([]);
  const [graphLoader, setGraphLoader] = useState(true);
  const [graphLoader1, setGraphLoader1] = useState(true);
  const [manager, setManager] = useState({});
  const [members, setMembers] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(true);
  const [open4, setOpen4] = useState(true);
  const [open5, setOpen5] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getTasksCompleted = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/analytics/manager/project-category/task/progress/`,
        {
          params: { projectId: id, category },
        }
      );
      console.log('taskCompleted', res);
      setTaskCompleted(res.data.data.completed);
    } catch (error) {
      console.log(error);
    }
  };

  const getTaskCountsPieData = async () => {
    try {
      setGraphLoader1(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/manager/project-category/tasks`, {
        params: { projectId: id, category },
      });
      console.log('tasks status pie', res);
      setTaskCountsGraphData(res.data.data.data);
      setGraphLoader1(false);
    } catch (error) {
      console.log(error);
      setGraphLoader1(false);
    }
  };

  const getStoragePieData = async () => {
    try {
      setGraphLoader(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/manager/project-category/task/storage`, {
        params: { projectId: id, category },
      });
      console.log('storage pie', res);
      setStorageUsage(res.data.data.data);
      setStorageFormat(res.data.data.format);
      setGraphLoader(false);
    } catch (error) {
      console.log(error);
      setGraphLoader(false);
    }
  };

  const getMembers = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/manager/project-category/members/`, {
        params: { projectId: id, category },
      });
      console.log('memberss', res);
      setMembers(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const getTaskList = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/manager/project-category/tasklist`, {
        params: { projectId: id, category },
      });
      console.log('taskList', res);
      setTaskList(res.data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = () => {
    setOpen(!open);
    setOpen1(true);
    setOpen2(true);
    setOpen3(true);
    setOpen4(true);
    setOpen5(true);
  };
  const handleClick1 = () => {
    setOpen1(!open1);
    setOpen(true);
    setOpen2(true);
    setOpen3(true);
    setOpen4(true);
    setOpen5(true);
  };
  const handleClick2 = () => {
    setOpen2(!open2);
    setOpen1(true);
    setOpen(true);
    setOpen3(true);
    setOpen4(true);
    setOpen5(true);
  };
  const handleClick3 = () => {
    setOpen3(!open3);
    setOpen1(true);
    setOpen2(true);
    setOpen(true);
    setOpen4(true);
    setOpen5(true);
  };
  const handleClick4 = () => {
    setOpen4(!open4);
    setOpen1(true);
    setOpen2(true);
    setOpen3(true);
    setOpen(true);
    setOpen5(true);
  };
  const handleClick5 = () => {
    setOpen5(!open5);
    setOpen1(true);
    setOpen2(true);
    setOpen3(true);
    setOpen4(true);
    setOpen(true);
  };

  useEffect(() => {
    getTasksCompleted();
    getMembers();
    getTaskCountsPieData();
    getTaskList();
    getStoragePieData();
  }, []);

  return (
    <Page title="Analytics">
      <Container maxWidth="xl">
        <Card>
          <Stack sx={{ width: '100%' }} paddingX={1} paddingTop={1} justifyContent="flex-end" direction="row">
            <CloseRoundedIcon sx={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
          </Stack>
          <Stack direction="row" justifyContent="center" alignItems="center" padding={2} sx={{ marginTop: '-32px' }}>
            <Typography variant="h4"> {category}</Typography>
          </Stack>
        </Card>

        <Box
          // padding={1}
          // paddingBottom={2}
          // mt={1}
          // sx={{
          //   bgcolor: '#fff',
          //   borderRadius: '10px',
          //   boxSshadow: ' rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
          // }}
          marginTop={1}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="flex-start">
                <Typography variant="h6">Tasks Completed</Typography>
              </Stack>
              <ProgressBar
                completed={taskCompleted}
                labelAlignment="left"
                baseBgColor="#D8D2C8"
                bgColor="#654A22"
                maxCompleted={100}
              />
            </Grid>

            <Grid item xs={12} md={8} lg={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Card
                    style={{
                      paddingBottom: '10px',
                      height: '458px',
                    }}
                  >
                    <Box padding={2}>
                      <Stack
                        direction="row"
                        spacing={1}
                        paddingBottom="15px"
                        alignItems={'center'}
                        justifyContent="center"
                      >
                        <Typography variant="h5">{category}</Typography>
                      </Stack>
                      <Box
                        style={{
                          maxHeight: '380px',
                          overflowY: 'auto',
                        }}
                        className="scrollCard"
                        padding={1}
                      >
                        <Grid container sx={12} direction="column" spacing={2}>
                          {taskList.map((name, id) => (
                            <Grid key={id} item>
                              <Typography style={{ fontSize: '15px', fontWeight: 'bold', paddingLeft: '3px' }}>
                                {name.name}
                              </Typography>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <PieChartCount
                    title="TASKS"
                    chartColors={['#EE4B2B', '#CF9FFF', '#FF7518', '#98FB98', '#50688C']}
                    chartData={taskCountsGraphData}
                    graphloader={graphLoader1}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <PieChartProjectStorage
                    title="STORAGE USED"
                    chartData={storageUsage}
                    graphloader={graphLoader}
                    format={storageFormat}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              {isLoading ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '50vh',
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <Grid container>
                  <Grid item xs={12}>
                    <Card sx={{ backgroundColor: '#ede8e8', minHeight: '454px' }}>
                      <Box
                        style={{
                          marginLeft: { lg: '5px', xl: '5px', md: '2px', sm: '0px', xs: '0px' },
                          backgroundColor: '#ede8e8',
                        }}
                      >
                        <ListItemButton onClick={handleClick}>
                          <ListItemText style={{ fontWeight: 'bold' }} primary="CLIENT" />
                          {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={!open}>
                          <List component="div" disablePadding>
                            {members
                              .filter((item) => item.accType === 'CLIENT')
                              .map((mdata, id) => (
                                <ListItem key={id} fullWidth>
                                  <ListItemAvatar>
                                    <Badge
                                      invisible={!mdata.isBlocked}
                                      overlap="circular"
                                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                      badgeContent={<Icon fontSize="20px" icon="material-symbols:block" color="red" />}
                                    >
                                      <Avatar alt="Remy Sharp" src="https://www.w3schools.com/howto/img_avatar.png" />
                                    </Badge>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={mdata.fullname.charAt(0).toUpperCase() + mdata.fullname.slice(1)}
                                    secondary={mdata.email}
                                  />
                                </ListItem>
                              ))}
                          </List>
                        </Collapse>
                      </Box>

                      <Box
                        style={{
                          marginLeft: { lg: '5px', xl: '5px', md: '2px', sm: '0px', xs: '0px' },
                          backgroundColor: '#ede8e8',
                        }}
                      >
                        <ListItemButton onClick={handleClick5}>
                          <ListItemText style={{ fontWeight: 'bold' }} primary="MANAGER" />
                          {open5 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={!open5}>
                          <List component="div" disablePadding>
                            {members
                              .filter((item) => item.role === 'MANAGER')
                              .map((mdata, id) => (
                                <ListItem key={id} fullWidth>
                                  <ListItemAvatar>
                                    <Badge
                                      invisible={!mdata.isBlocked}
                                      overlap="circular"
                                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                      badgeContent={<Icon fontSize="20px" icon="material-symbols:block" color="red" />}
                                    >
                                      <Avatar alt="Remy Sharp" src="https://www.w3schools.com/howto/img_avatar.png" />
                                    </Badge>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={mdata.fullname.charAt(0).toUpperCase() + mdata.fullname.slice(1)}
                                    secondary={mdata.email}
                                  />
                                </ListItem>
                              ))}
                          </List>
                        </Collapse>
                      </Box>
                      <Box
                        style={{
                          marginLeft: { lg: '5px', xl: '5px', md: '2px', sm: '0px', xs: '0px' },
                          backgroundColor: '#ede8e8',
                        }}
                      >
                        <ListItemButton onClick={handleClick1}>
                          <ListItemText style={{ fontWeight: 'bold' }} primary="PARTNERS" />
                          {open1 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={!open1}>
                          <List component="div" disablePadding>
                            {members
                              .filter((item) => item.accType === 'PARTNER' && item.role !== 'MANAGER')
                              .map((mdata, id) => (
                                <ListItem key={id} fullWidth>
                                  <ListItemAvatar>
                                    <Badge
                                      invisible={!mdata.isBlocked}
                                      overlap="circular"
                                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                      badgeContent={<Icon fontSize="20px" icon="material-symbols:block" color="red" />}
                                    >
                                      <Avatar alt="Remy Sharp" src="https://www.w3schools.com/howto/img_avatar.png" />
                                    </Badge>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={mdata.fullname.charAt(0).toUpperCase() + mdata.fullname.slice(1)}
                                    secondary={mdata.email}
                                  />
                                </ListItem>
                              ))}
                          </List>
                        </Collapse>
                      </Box>

                      <Box
                        style={{
                          marginLeft: { lg: '5px', xl: '5px', md: '2px', sm: '0px', xs: '0px' },
                          backgroundColor: '#ede8e8',
                        }}
                      >
                        <ListItemButton onClick={handleClick2}>
                          <ListItemText style={{ fontWeight: 'bold' }} primary="CONTRACTORS" />
                          {open2 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={!open2}>
                          <List component="div" disablePadding>
                            {members
                              .filter((item) => item.accType === 'CONTRACTOR' && item.role !== 'MANAGER')
                              .map((mdata, id) => (
                                <ListItem key={id} fullWidth>
                                  <ListItemAvatar>
                                    <Badge
                                      invisible={!mdata.isBlocked}
                                      overlap="circular"
                                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                      badgeContent={<Icon fontSize="20px" icon="material-symbols:block" color="red" />}
                                    >
                                      <Avatar alt="Remy Sharp" src="https://www.w3schools.com/howto/img_avatar.png" />
                                    </Badge>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={mdata.fullname.charAt(0).toUpperCase() + mdata.fullname.slice(1)}
                                    secondary={mdata.email}
                                  />
                                </ListItem>
                              ))}
                          </List>
                        </Collapse>
                      </Box>

                      <Box
                        style={{
                          marginLeft: { lg: '5px', xl: '5px', md: '2px', sm: '0px', xs: '0px' },
                          backgroundColor: '#ede8e8',
                        }}
                      >
                        <ListItemButton onClick={handleClick3}>
                          <ListItemText style={{ fontWeight: 'bold' }} primary="ARCHITECTS" />
                          {open3 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={!open3}>
                          <List component="div" disablePadding>
                            {members
                              .filter((item) => item.accType === 'ARCHITECT' && item.role !== 'MANAGER')
                              .map((mdata, id) => (
                                <ListItem key={id} fullWidth>
                                  <ListItemAvatar>
                                    <Badge
                                      invisible={!mdata.isBlocked}
                                      overlap="circular"
                                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                      badgeContent={<Icon fontSize="20px" icon="material-symbols:block" color="red" />}
                                    >
                                      <Avatar alt="Remy Sharp" src="https://www.w3schools.com/howto/img_avatar.png" />
                                    </Badge>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={mdata.fullname.charAt(0).toUpperCase() + mdata.fullname.slice(1)}
                                    secondary={mdata.email}
                                  />
                                </ListItem>
                              ))}
                          </List>
                        </Collapse>
                      </Box>

                      <Box
                        style={{
                          marginLeft: { lg: '5px', xl: '5px', md: '2px', sm: '0px', xs: '0px' },
                          backgroundColor: '#ede8e8',
                        }}
                      >
                        <ListItemButton onClick={handleClick4}>
                          <ListItemText style={{ fontWeight: 'bold' }} primary="EMPLOYEES" />
                          {open4 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={!open4}>
                          <List component="div" disablePadding>
                            {members
                              .filter((item) => item.accType === 'EMPLOYEE' && item.role !== 'MANAGER')
                              .map((mdata, id) => (
                                <ListItem key={id} fullWidth>
                                  <ListItemAvatar>
                                    <Badge
                                      invisible={!mdata.isBlocked}
                                      overlap="circular"
                                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                      badgeContent={<Icon fontSize="20px" icon="material-symbols:block" color="red" />}
                                    >
                                      <Avatar alt="Remy Sharp" src="https://www.w3schools.com/howto/img_avatar.png" />
                                    </Badge>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={mdata.fullname.charAt(0).toUpperCase() + mdata.fullname.slice(1)}
                                    secondary={mdata.email}
                                  />
                                </ListItem>
                              ))}
                          </List>
                        </Collapse>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}
