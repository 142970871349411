import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import { useSelector } from 'react-redux';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import AddUser from './components/AddUser';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Projects from './pages/Projects';
import ViewProject from './pages/ViewProject';
import Task from './pages/Task';
import { CreateTask } from './pages/CreateTask';
import { ViewTask } from './pages/ViewTask';
import { SubTaskView } from './pages/SubTaskView';
import { CreateTaskFromTask } from './pages/CreateTaskFromTask';
import Billing from './pages/Billing';
import ProjectAnalytics from './pages/ProjectAnalytics';
import CategoryAnalytics from './pages/CategoryAnalytics';
import ToDo from './pages/ToDo';
import TaskCheckListView from './pages/TaskCheckListView';
import Profile from './pages/Profile';
import TaskAttachments from './pages/TaskAttachments';

// ----------------------------------------------------------------------

export default function Router() {
  // const { isAuth } = useSelector((state) => state.login);
  const accessToken = localStorage.getItem('accessToken');
  return useRoutes([
    {
      path: '/dashboard',
      element: accessToken ? <DashboardLayout /> : <Navigate to="/" />,
      // element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'project', element: <Projects /> },
        { path: 'project/:id', element: <ViewProject /> },
        { path: 'project/task', element: <SubTaskView /> },
        { path: 'project/create/:id', element: <CreateTask /> },
        { path: 'task/:id', element: <Task /> },
        { path: 'project/task/view', element: <ViewTask /> },
        { path: 'project/task/subtask/view', element: <TaskCheckListView /> },
        { path: 'project/task/attachments/:id', element: <TaskAttachments /> },
        { path: 'billing/:id', element: <Billing /> },
        { path: 'project/task/create', element: <CreateTaskFromTask /> },
        { path: 'project-analytics/:id', element: <ProjectAnalytics /> },
        { path: 'category-analytics', element: <CategoryAnalytics /> },
        { path: 'to-do', element: <ToDo /> },
        { path: 'profile', element: <Profile /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        // { path: '/', element: <Navigate to="/dashboard/app" /> },
        // { path: 'login', element: <Login /> },
        { path: '/', element: accessToken ? <Navigate to="/dashboard/app" /> : <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'adduser', element: <AddUser /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
