import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Checkbox, CircularProgress, Container, Grid, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Add, KeyboardArrowRight } from '@mui/icons-material';
import ProgressBar from '@ramonak/react-progress-bar';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import BackIcon from '../assets/icons/back.png';
import BreadCrumps from '../components/BreadCrumps';
import Attachments from '../components/Attachments';
import SubTaskFileUploadModal from '../components/SubTaskFileUploadModal';
import Notes from '../components/Notes';
import AddCommentModal from '../components/AddCommentModal';

function TaskCheckListView() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const taskId = searchParams.get('taskId');
  const subTaskId = searchParams.get('subTaskId');

  const [isLoading, setIsLoading] = useState(true);
  const [subTask, setSubTask] = useState({});
  const [names, setNames] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const [buttonLoading, setButtonLoading] = useState(false);

  const getSubTask = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/subtask/user/one`, {
        params: {
          subTaskId,
          taskId,
        },
      });
      console.log(res);
      setIsLoading(false);
      if (!res.data.isError) {
        setSubTask(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getNames = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/task/user/names`, {
        params: {
          id: taskId,
        },
      });
      console.log(res);
      if (!res.data.isError) {
        setNames(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStateChange = async (state, id) => {
    try {
      setButtonLoading(true);
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/subtask/user/checklist/status`, {
        subTaskId,
        taskId,
        isCompleted: state,
        checkListId: id,
      });
      getSubTask();
      setButtonLoading(false);
      toast.success('Sub Task Updated Successfully');
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      getSubTask();
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    getSubTask();
    getNames();
  }, []);

  return (
    <Page title="Sub Task">
      {isLoading ? (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <Grid container spacing={5} rowGap={2}>
            <Grid item xs={12}>
              <BreadCrumps
                project={{
                  name: names?.projectName,
                  id: names?.projectId,
                }}
                task={{
                  name: names?.taskName,
                  id: names?.taskId,
                }}
                category={{
                  name: names?.category,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction={'column'}
                spacing={2}
                sx={{
                  borderRadius: '6px',
                  padding: 3,
                  cursor: 'pointer',
                  backgroundColor: '#fff',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Avatar alt="" src={subTask?.userData?.profilePic}>
                      {subTask?.userData?.fullname?.charAt(0).toUpperCase()}
                    </Avatar>
                    <Typography
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        width: '200px',
                        fontWeight: 600,
                        fontSize: '16px',
                        color: '#2E2E2E',
                        fontFamily: 'Sora , sans-serif',
                      }}
                    >
                      {subTask?.userData?.fullname}
                    </Typography>
                  </Stack>
                </Stack>
                <Box>
                  <Typography
                    sx={{
                      width: '100%',
                      fontWeight: 600,
                      fontSize: '14px',
                      color: '#2e2e2e',
                      fontFamily: 'Sora , sans-serif',
                    }}
                  >
                    {subTask?.name}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      width: '100%',
                      fontWeight: 400,
                      fontSize: '14px',
                      color: '#6d6d6d',
                      fontFamily: 'Sora , sans-serif',
                    }}
                    mb={1}
                  >
                    {subTask?.description}
                  </Typography>
                </Box>

                <Stack direction="column" spacing={1}>
                  <Typography
                    sx={{
                      color: '#2E2E2E',
                      fontFamily: 'Work Sans',
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '5px',
                    }}
                  >
                    Progress
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    sx={{
                      width: '100%',
                    }}
                  >
                    <Box sx={{ width: '100%' }}>
                      <ProgressBar
                        completed={subTask?.progress}
                        baseBgColor="#C0BEBE"
                        bgColor={subTask?.progress < 60 ? '#5297FF' : '#0ED652'}
                        maxCompleted={100}
                        height="10px"
                        width="100%"
                        customLabelStyles={{
                          fontSize: '10px',
                          color: `${subTask?.progress < 60 ? '#5297FF' : '#0ED652'}`,
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Work Sans',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                      }}
                    >
                      {subTask.progress}%
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'flex-start'} pt={2}>
                    <Iconify icon={'mingcute:attachment-line'} color={'#2E2E2E'} />
                    <Typography
                      sx={{
                        fontFamily: 'Work Sans' ,
                        fontSize: '14px',
                        fontWeight: 500,
                        color: '#6d6d6d',
                      }}
                    >
                      {`${subTask?.attachmentsCount ? subTask.attachmentsCount : 0} Files`}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack gap={2}>
                  {subTask?.checkList?.map((checkListItem, index) => (
                    <Stack key={index} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography
                        sx={{
                          fontFamily: 'Work Sans',
                          fontSize: '14px',
                          fontWeight: 500,
                          color: '#2e2e2e',
                        }}
                      >
                        {`${index + 1}. ${checkListItem.name}`}
                      </Typography>
                      <Checkbox
                        checked={checkListItem.isCompleted}
                        onChange={() => handleStateChange(!checkListItem.isCompleted, checkListItem._id)}
                        color={checkListItem.isCompleted ? 'success' : 'default'}
                        sx={{
                          cursor: 'pointer',
                        }}
                        disabled={buttonLoading}
                      />
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography
                  sx={{
                    color: '#2E2E2E',
                    fontFamily: 'Sora',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    letterSpacing: '-0.08px',
                  }}
                >
                  {subTask?.attachments && !!subTask.attachments.length ? 'Attachments' : 'No Attachments'}
                </Typography>
                <SubTaskFileUploadModal getSubTask={getSubTask} />
              </Stack>
              {subTask?.attachments && !!subTask.attachments.length && (
                <Attachments attachments={subTask?.attachments} />
              )}
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography
                  sx={{
                    color: '#2E2E2E',
                    fontFamily: 'Sora',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    letterSpacing: '-0.08px',
                  }}
                >
                  {subTask?.comments && !!subTask.comments.length ? 'Comments' : 'No Comments'}
                </Typography>
              </Stack>
              <AddCommentModal getSubTask={getSubTask} taskId={taskId} subTaskId={subTaskId} />
            </Grid>
            <Grid item xs={12}>
              {subTask?.comments && !!subTask.comments.length && (
                <Notes comments={subTask?.comments} userData={subTask?.userData} />
              )}
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
}

export default TaskCheckListView;
