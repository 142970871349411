import { AddCircleOutline, Delete, FiberManualRecord } from '@mui/icons-material';
import { Box, Button, Grid, Stack, TablePagination, Typography } from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import React from 'react';
// import AddNote from './AddNote';
// import DeleteNote from './DeleteNote';

function NoteItem({ note, handleDelete }) {
  return (
    <Grid container>
      <Grid item xs={9} sm={10} md={10}>
        <Stack direction="row" spacing={1}>
          {/* <FiberManualRecord sx={{ color: '#000000', fontSize: '12px' }} /> */}
          <Typography
            sx={{
              color: '#000',
              fontFamily: 'Sora',
              fontSize: '13px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '187.5%',
            }}
          >
            {note.note}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={2} sm={1} md={1}>
        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} sx={{ width: '100%' }}>
          <Typography
            sx={{
              color: '#000',
              fontFamily: 'Sora',
              fontSize: '13px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '187.5%',
              whiteSpace:"nowrap"
            }}
          >
            {moment(note.createdAt).format('DD MMM YYYY')}
          </Typography>
        </Stack>
      </Grid>
      {/* <Grid item xs={1} sm={1} md={1}>
        <Stack direction="row" spacing={1} sx={{ width: '100%' }} justifyContent={'end'}>
          <Box
            sx={{
              cursor: 'pointer',
              background: '#FF1800',
              '&:hover': {
                background: '#cf200c',
              },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '3px',
              borderRadius: '5px',
            }}
            onClick={() => handleDelete(note._id)}
          >
            <Delete sx={{ color: '#fff', fontSize: '16px' }} />
          </Box>
        </Stack>
      </Grid> */}
    </Grid>
  );
}

function MemberNotes() {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [noteId, setNoteId] = React.useState('');
  const [notes, setNotes] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [notesCount, setNotesCount] = React.useState(0);
  const [options, setOptions] = React.useState({ page: 0, size: 10 });

  const getNotes = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/own-notes`, {
        params: {
          ...options,
        },
      });
      console.log(res.data.data);
      setNotes(res.data.data.notes);
      setNotesCount(res.data.data.maxRecords);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddNote = () => {
    setOpen(true);
  };

  const handleDelete = (noteId) => {
    setNoteId(noteId);
    setOpenDelete(true);
  };

  React.useEffect(() => {
    getNotes();
  }, [options]);

  return (
    <Box>
      {' '}
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 5 }}>
        <Typography
          sx={{
            color: '#0B132B',
            fontFamily: 'Sora',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            letterSpacing: '-0.1px',
          }}
        >
          Notes
        </Typography>
        {/* <Button
          variant="contained"
          sx={{
            bgcolor: '#0096DB',
            '&:hover': {
              bgcolor: '#057ab0',
            },
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal',
            letterSpacing: '0.256px',
          }}
          startIcon={<AddCircleOutline />}
          onClick={handleAddNote}
        >
          Note
        </Button> */}
        {/* <AddNote open={open} setOpen={setOpen} userId={id} getNotes={getNotes} /> */}
      </Stack>
      <Stack direction="column" spacing={4}>
        <ul>
          {notes.map((note) => (
            <li style={{ marginBottom: '24px' }} key={note.id}>
              <NoteItem note={note} handleDelete={handleDelete} />
            </li>
          ))}
        </ul>
        {/* {openDelete && (
          <DeleteNote open={openDelete} setOpen={setOpenDelete} userId={id} noteId={noteId} getNotes={getNotes} />
        )} */}
      </Stack>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={notesCount}
        page={options.page}
        onPageChange={(e, page) => setOptions({ ...options, page })}
        rowsPerPage={options.size}
        sx={{
          '& .MuiTablePagination-displayedRows': {
            color: '#2E2E2E',
            fontFamily: 'Sora',
            fontSize: '14px',
            fontWeight: 400,
          },
        }}
      />
    </Box>
  );
}

export default MemberNotes;
