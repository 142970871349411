import { AttachFile, CalendarToday } from '@mui/icons-material';
import { Avatar, AvatarGroup, Badge, Box, Button, Icon, Stack, Tooltip, Typography, Zoom } from '@mui/material';
import ProgressBar from '@ramonak/react-progress-bar';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

function TaskCard({ task }) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [subTaskCountData, setSubTaskCountData] = useState({});

  const getSubTaskCount = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/subtask/user/count-in-task`, {
        params: { taskId: task?._id },
      });
      setSubTaskCountData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (task && task._id) {
      getSubTaskCount();
    }
  }, [task]);

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#fff',
        borderRadius: '6px',
        height: '100%',
      }}
      padding={3}
    >
      <Stack spacing={3}>
        <Typography
          sx={{
            color: '#2E2E2E',
            fontFamily: 'Sora',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            letterSpacing: '-0.1px',
          }}
        >
          {task.name}
        </Typography>
        <Stack direction={'row'} spacing={1}>
          <CalendarToday
            sx={{
              fontSize: '16px',
              color: '#63A3C0',
            }}
          />
          <Typography
            sx={{
              color: '#2E2E2E',
              fontFamily: 'Sora',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              letterSpacing: '-0.07px',
            }}
          >
            {task?.startDate ? moment(task.startDate).format('MMMM Do, YYYY') : 'N/A'}
          </Typography>
        </Stack>

        {/* <Typography
          sx={{
            color: 'rgba(46, 46, 46, 0.70)',
            fontFamily: 'Work Sans',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            height: '114px',
            overflowY: 'scroll',
          }}
          marginBottom={'50px'}
          className="scrollCard"
        > 
          {task.description}
        </Typography> */}
        <Stack spacing={0.5}>
          <Typography
            sx={{
              color: '#2E2E2E',
              fontFamily: 'Work Sans',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
            }}
          >
            Members
          </Typography>
          <Box
            sx={{
              width: '100%',
              paddingLeft: '10px',
            }}
          >
            <AvatarGroup max={6} sx={{ display: 'flex', justifyContent: 'left' }}>
              {task?.members &&
                task.members.length > 0 &&
                task.members.map((mdata, id) => (
                  <Tooltip key={id} TransitionComponent={Zoom} title={mdata.email}>
                    <Badge
                      invisible={!mdata.isBlocked}
                      overlap="circular"
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      badgeContent={<Icon fontSize="20px" icon="material-symbols:block" />}
                    >
                      <Avatar alt="" key={id} src={mdata.profilePic}>
                        {mdata.fullname.charAt(0).toUpperCase()}
                      </Avatar>
                    </Badge>
                  </Tooltip>
                ))}
            </AvatarGroup>
          </Box>
        </Stack>
        <Box
          sx={{
            height: '60px',
          }}
        >
          {subTaskCountData?.total > 0 && (
            <Stack spacing={0.5}>
              <Typography
                sx={{
                  color: '#2E2E2E',
                  fontFamily: 'Work Sans',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: 'normal',
                }}
              >
                Sub Tasks ({`${subTaskCountData?.completed}/${subTaskCountData?.total}`})
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{
                  width: '100%',
                }}
              >
                <Box sx={{ width: '100%' }}>
                  <ProgressBar
                    completed={subTaskCountData?.progress || 0}
                    baseBgColor="#C0BEBE"
                    bgColor={subTaskCountData?.progress < 60 ? '#5297FF' : '#0ED652'}
                    maxCompleted={100}
                    height="10px"
                    width="100%"
                    customLabelStyles={{
                      fontSize: '10px',
                      color: `${subTaskCountData?.progress < 60 ? '#5297FF' : '#0ED652'}`,
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    color: '#000',
                    fontFamily: 'Work Sans',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                  }}
                >
                  {subTaskCountData?.progress}%
                </Typography>
              </Stack>
            </Stack>
          )}
        </Box>

        <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} paddingTop={3}>
          <Stack direction="row" alignItems={'center'} spacing={0.5}>
            <AttachFile sx={{ color: '#2E2E2E' }} />
            <Typography
              sx={{
                color: '#6D6D6D',
                fontFamily: 'Work Sans',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                letterSpacing: '-0.016px',
              }}
            >
              {task?.attachmentsCount} Files
            </Typography>
          </Stack>
          <Button
            color="buttonone"
            variant="contained"
            onClick={() => navigate(`/dashboard/project/task/view?projectId=${id}&taskId=${task._id}`)}
          >
            View More
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}

export default TaskCard;
