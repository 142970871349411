import { CloseRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogContent, DialogTitle, Grid, Stack, TextField, Tooltip, Zoom } from '@mui/material';
import React from 'react';
import * as Yup from 'yup';
import Moment from 'moment';
import { Form, useFormik, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
import axios from 'axios';

function AddCommentModal({ getSubTask, taskId, subTaskId }) {
  const commentSchema = Yup.object().shape({
    taskId: Yup.string().required('Task is required'),
    subTaskId: Yup.string().required('Sub Task is required'),
    comment: Yup.string().required('Comment is required'),
  });
  const formik = useFormik({
    initialValues: {
      taskId,
      subTaskId,
      comment: '',
    },
    validationSchema: commentSchema,
    onSubmit: async () => {
      console.log('values', values);
      try {
        await axios
          .put(`${process.env.REACT_APP_API_URL}/subtask/user/comment`, values)
          .then((res) => {
            formik.resetForm();
            toast.success(res.data.message);
            getSubTask();
          })
          .catch((err) => {
            toast.error(err.response.data.message);
            console.log(err);
          });
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, values, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container mt={1} spacing={1.5}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              label="Comment"
              {...getFieldProps('comment')}
              InputProps={{
                style: {
                  backgroundColor: '#fff',
                  border: 'none',
                  boxShadow: 'none',
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction={'row'} justifyContent="flex-end">
              <LoadingButton
                loading={isSubmitting}
                disabled={errors.comment || !values.comment}
                variant="contained"
                type="submit"
                sx={{ width: '100px' }}
                color="buttonone"
              >
                Add
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

export default AddCommentModal;
