import { createSlice } from '@reduxjs/toolkit';

export const sidebarCollapseSlice = createSlice({
  name: 'sideBarCollapse',
  initialState: {
    isCollapsed: false,
  },
  reducers: {
    setSideBarCollapse: (state, action) => {
      state.isCollapsed = action.payload;
    },
  },
});

export const { setSideBarCollapse } = sidebarCollapseSlice.actions;

export default sidebarCollapseSlice.reducer;
