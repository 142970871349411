import io from 'socket.io-client';
import moment from 'moment';
import {
  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Box,
  Stack,
  TextField,
  Button,
  Grid,
  Tooltip,
  Zoom,
  Autocomplete,
  FormControl,
  MenuItem,
  Select,
  CircularProgress,
  Avatar,
  IconButton,
  Badge,
  Menu,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { toast } from 'react-toastify';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import ForumIcon from '@mui/icons-material/Forum';
import { Form, useFormik, FormikProvider } from 'formik';
import ProgressBar from '@ramonak/react-progress-bar';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Edit, MoreVert, CalendarToday, Category } from '@mui/icons-material';

import React, { useEffect, useState } from 'react';
import Page from '../components/Page';
import Chat from '../components/chat/Chat';
import BreadCrumps from '../components/BreadCrumps';
import Iconify from '../components/Iconify';
import SubTasks from '../components/SubTasks';
import TaskAttachments from '../components/TaskAttachments';

const socket = io(`${process.env.REACT_APP_API_URL}`);

const TypographyStyle1 = {
  color: 'rgba(46, 46, 46, 0.80)',
  fontFamily: 'Sora',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  letterSpacing: '-0.08px',
};

const TypographyStyle2 = {
  color: '#2E2E2E',
  fontFamily: 'Sora',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '-0.08px',
};

const headingStyle = {
  color: '#2E2E2E',
  fontFamily: 'Sora',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  letterSpacing: '-0.09px',
};

const contentStyle = {
  color: '#2E2E2E',
  fontFamily: 'Sora',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '-0.08px',
};

const memberHeadingStyle = {
  color: 'rgba(46, 46, 46, 0.80)',
  fontFamily: 'Sora',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: 'normal',
  letterSpacing: '-0.08px',
};

const setColorByStatus = (status) => {
  switch (status) {
    case 'PENDING':
      return '#ea9903 ';
    case 'IN_PROGRESS':
      return '#0063cc ';
    case 'COMPLETED':
      return '#1f8036 ';
    case 'PAUSED':
      return '#d9b900 ';
    case 'CANCELLED':
      return '#b22a38 ';
    default:
      return '#d88c01';
  }
};

const statusesAndPrint = [
  { value: 'PENDING', label: 'Pending' },
  { value: 'IN_PROGRESS', label: 'In progress' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'PAUSED', label: 'Paused' },
  { value: 'CANCELLED', label: 'Cancelled' },
];

export const ViewTask = () => {
  const [task, setTask] = useState();
  const [attachments, setAttachments] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [manager, setManager] = useState(false);
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [open, setOpen] = useState(true);
  const [open2, setOpen2] = useState({ flag: false, id: null });
  const [open1, setOpen1] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [projectMembers, setProjectMembers] = useState([]);
  const taskId = searchParams.get('taskId');
  const projectId = searchParams.get('projectId');
  const [anchorEl, setAnchorEl] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [subTaskCountData, setSubTaskCountData] = useState({});

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')).id);

  const fabStyle = {
    right: '5vw',
    position: 'fixed',
    top: '80vh',
    zIndex: '999',
    cursor: 'pointer',
  };

  const checkManager = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/manager`, {
        params: { projectId },
      });
      setManager(res.data.data.manager);
      if (res.data.data.manager) {
        getProjectMembers();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const viewTask = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/task/user`, {
        params: { id: taskId },
      })
      .then((res) => {
        console.log('task', res.data.data.task);
        setTask(res.data.data.task);
        setAttachments(res.data.data.task.attachment);
        setDescription(res.data.data.task.description);
        setStartDate(res.data.data.task.startDate);
        setEndDate(res.data.data.task.endDate);
        const { members } = res.data.data.task;
        const member = members.find((e) => e.userId._id === user);
        setUnreadMessages(member.unreadCount);
        setIsLoading(false);
      });
  };
  const getProjectMembers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/task/manager/nonmembers`, {
        params: { projectId, taskId },
      });
      setProjectMembers(res.data.data.members.filter((item) => item.isBlocked === false));
    } catch (error) {
      console.log(error);
    }
  };
  const formik = useFormik({
    initialValues: {
      userIds: [],
      taskId,
      projectId,
    },

    onSubmit: async () => {
      console.log('values', values);
      try {
        await axios
          .put(`${process.env.REACT_APP_API_URL}/task/manager/addmembers`, values)
          .then((res) => {
            toast.success(res.data.message);
            handleClose();
            callApis();
          })
          .catch((err) => {
            toast.error('something went wrong');
            console.log(err);
          });
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  });
  const removeMember = async (userId) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/task/manager/removemember`, {
        userId: open2.id,
        taskId,
        projectId,
      });
      toast.success(res.data.message);
      setOpen2({ flag: false, id: null });
      callApis();
    } catch (error) {
      console.log(error);
    }
  };
  const callApis = async () => {
    viewTask();
  };

  const handleDialog = () => {
    setOpen1(true);
  };
  const handleDeletePopup = (id) => {
    setOpen2({ flag: true, id });
  };
  const handleClose = () => {
    setOpen1(false);
    setOpen2({ flag: false, id: null });
  };
  const handleStatusChange = async (event) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/task/manager/status`, {
        status: event.target.value,
        taskId,
        projectId,
      });
      viewTask();
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const updateTask = async () => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/task/manager/edit`, {
        taskId,
        description,
        startDate,
        endDate,
      });
      toast.success('Task Description Successfully Updated');
      viewTask();
    } catch (error) {
      console.log(error);
      toast.error(error.resposnse.data.message);
    }
  };

  const handleChatOpen = () => {
    setOpen3(true);
    setTimeout(() => {
      setUnreadMessages(0);
    }, 500);
  };

  const handleChatClose = () => {
    setOpen3(false);
    viewTask();
  };

  useEffect(() => {
    socket.connect();
    socket.on('TASK_MESSAGE', (data) => {
      if (data.taskId === taskId && !open3) {
        setUnreadMessages((prev) => prev + 1);
      }
    });
    return () => {
      socket.off('TASK_MESSAGE');
      // socket.disconnect();
    };
  }, [unreadMessages]);

  useEffect(() => {
    checkManager();
  }, [projectId]);

  useEffect(() => {
    callApis();
  }, [manager, taskId, projectId]);

  const { errors, touched, handleSubmit, handleChange, setFieldValue, values } = formik;

  return (
    <Page>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <Badge
            overlap="circular"
            onClick={handleChatOpen}
            invisible={unreadMessages < 1}
            style={fabStyle}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            badgeContent={
              !!unreadMessages && (
                <Avatar
                  sx={{
                    ...(unreadMessages < 10 && { width: 24, height: 24 }),
                    ...(unreadMessages >= 10 && unreadMessages < 100 && { width: 28, height: 28 }),
                    ...(unreadMessages >= 100 && unreadMessages < 100 && { width: 37, height: 28 }),
                    border: 'solid white 3px',
                    bgcolor: 'white',
                    color: '#242c42',
                  }}
                >
                  <Typography>{(!!unreadMessages && unreadMessages) || 0}</Typography>
                </Avatar>
              )
            }
          >
            <Avatar
              size="large"
              className="fab"
              sx={{ width: 54, height: 54, bgcolor: '#475985', ':hover': { background: '#394970' } }}
              color="primary"
              aria-label="add"
            >
              <ForumIcon sx={{ fontSize: '35px', color: 'white' }} />
            </Avatar>
          </Badge>
          {/* chat dialog */}
          {open3 && <Chat handleChatClose={handleChatClose} taskId={taskId} open3={open3} />}
          {/* add member dialog */}
          <Dialog
            open={open1}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <Stack direction="row" justifyContent={'space-between'} marginX={-1}>
                {'Add Members'}
                <Tooltip TransitionComponent={Zoom} title="Close">
                  <CloseRoundedIcon onClick={handleClose} sx={{ color: 'black', cursor: 'pointer' }} />
                </Tooltip>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <FormikProvider value={formik}>
                <Form onSubmit={handleSubmit}>
                  <Stack direction="column">
                    <FormControl sx={{ m: 1, width: { sm: '350px', xs: '250px' } }}>
                      <Autocomplete
                        multiple
                        id="multiple-limit-tags"
                        name="userIds"
                        options={projectMembers}
                        getOptionLabel={(option) => option.fullname}
                        onChange={(e, value) => {
                          const temp = value.map((item, index) => item._id);
                          setFieldValue('userIds', temp);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select members"
                            placeholder="Members"
                            fullWidth
                            onChange={handleChange}
                            error={Boolean(touched.userIds && errors.userIds)}
                          />
                        )}
                      />
                    </FormControl>
                    <Stack mt={2} padding={1} spacing={2} direction="row" justifyContent="center">
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{ width: '100px', bgcolor: '#475985', ':hover': { background: '#394970' } }}
                      >
                        Add
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              </FormikProvider>
            </DialogContent>
          </Dialog>
          {/* remove moember dialog */}
          <Dialog
            open={open2.flag}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'Remove member'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to remove this member from Project?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button sx={{ color: '#475985', ':hover': { bgcolor: '#DEE2ED' } }} onClick={handleClose}>
                No
              </Button>
              <Button sx={{ color: '#475985', ':hover': { bgcolor: '#DEE2ED' } }} onClick={removeMember} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          {/* <Grid>
            <Card
              style={{
                backgroundColor: '#dee2ed',
                marginBottom: '10px',
              }}
            >
             
             
            </Card>
            <Card style={{ padding: '5px' }}>
              <Stack
                direction={{ lg: 'row', md: 'row', sm: 'column-reverse', xs: 'column-reverse' }}
                spacing={2}
                style={{ display: 'flex', justifyContent: 'space-between', margin: '10px' }}
              >
                <Typography variant="h4">{task?.name.toUpperCase()}</Typography>
                <Stack spacing={1} direction="row" justifyContent={'space-between'}>
                  <Stack direction="row" spacing={1}>
                    
                    <FileUploadModal projectId={projectId} taskId={taskId} />
                  </Stack>
                </Stack>
              </Stack>
              <Grid
                container
                sx={12}
                direction="row"
              >
             
                <Grid container lg={4} md={5} sm={12} xs={12}>
                  <Box width="100%">
                    <Grid item md={12} sm={12} xs={12}>
                      <Card
                        style={{
                          padding: '10px',
                          marginLeft: { lg: '5px', xl: '5px', md: '2px', sm: '0px', xs: '0px' },
                          marginBottom: '10px',
                          backgroundColor: '#f0f2f7',
                        }}
                      >
                        <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
                          Manager
                        </Typography>
                        <Divider style={{ padding: '5px' }} />
                        {task.members.map((member, i) => {
                          return (
                            <>
                              {member.role === 'MANAGER' && (
                                <ListItem>
                                  <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src="https://www.w3schools.com/howto/img_avatar.png" />
                                  </ListItemAvatar>
                                  <Tooltip TransitionComponent={Zoom} title={member?.userId.email}>
                                    <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                      {member.userId.fullname.toUpperCase()}
                                    </Typography>
                                  </Tooltip>
                                </ListItem>
                              )}
                            </>
                          );
                        })}
                      </Card>
                    </Grid>

                    <Grid item md={12} sm={12} xs={12}>
                      <Box>
                        <Card
                          style={{
                            padding: '10px',
                            marginLeft: { lg: '5px', xl: '5px', md: '2px', sm: '0px', xs: '0px' },
                            marginBottom: '10px',
                            backgroundColor: '#f0f2f7',
                            maxHeight: '300px',
                            overflowY: 'scroll',
                          }}
                        >
                          <ListItemButton onClick={handleClick}>
                            <ListItemText style={{ fontWeight: 'bold' }} primary="Members" />
                            {open ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          <Collapse in={!open}>
                            <List component="div" disablePadding>
                              {task.members.map((member, i) => {
                                return (
                                  <>
                                    {member.role !== 'MANAGER' && (
                                      <>
                                        {' '}
                                        <ListItem>
                                          <ListItemAvatar>
                                            <Badge
                                              invisible={!member.userId.isBlocked}
                                              overlap="circular"
                                              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                              badgeContent={
                                                <Icon fontSize="20px" icon="material-symbols:block" color="red" />
                                              }
                                            >
                                              <Avatar
                                                alt="Remy Sharp"
                                                src="https://www.w3schools.com/howto/img_avatar.png"
                                              />
                                            </Badge>
                                          </ListItemAvatar>

                                          <Tooltip TransitionComponent={Zoom} title={member.userId.email}>
                                            <ListItemText
                                              primary={
                                                member.userId.fullname.charAt(0).toUpperCase() +
                                                member.userId.fullname.slice(1)
                                              }
                                            />
                                          </Tooltip>
                                          {manager && (
                                            <Stack direction="column" alignItems="flex-end">
                                              <ListItemButton
                                                onClick={() => handleDeletePopup(member.userId._id)}
                                                alignItems="flex-end"
                                              >
                                                <DeleteOutlinedIcon />
                                              </ListItemButton>
                                            </Stack>
                                          )}
                                        </ListItem>
                                      </>
                                    )}
                                  </>
                                );
                              })}
                            </List>
                          </Collapse>
                        </Card>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Stack direction={'row'} style={{ marginLeft: '30px' }}>
                <Typography variant="h4">File Attachments</Typography>
                <AttachmentIcon style={{ margin: '8px' }} />
              </Stack>
              <Stack direction={'row'} justifyContent="flex-start">
                <Stack
                  direction={'row'}
                  padding={matches ? 1 : 1}
                  sx={{
                    flexWrap: 'wrap',
                    ...(medium && { width: '780px' }),
                    ...(small && { width: '390px' }),
                  }}
                  columnGap={2}
                  alignItems="center"
                  rowGap={2}
                  justifyContent={'flex-start'}
                  alignContent="flex-start"
                >
                  {attachments.length >= 1 &&
                    attachments?.map((item, index) => {
                      return <CardForAttachment file={item} />;
                    })}
                </Stack>
              </Stack>
              {attachments.length < 1 && (
                <Stack
                  direction={'row'}
                  padding={matches ? 1 : 1}
                  sx={{ flexWrap: 'wrap', margin: 'auto' }}
                  columnGap={2}
                  alignItems="center"
                  rowGap={2}
                  justifyContent={'flex-start'}
                  alignContent="flex-start"
                >
                  <Box style={{ height: '200px', width: '100%' }}>
                    <Typography textAlign={'center'} style={{ padding: '70px 0px' }}>
                      No Attachments Found.
                    </Typography>
                  </Box>
                </Stack>
              )}
            </Card>
          </Grid> */}
          <Grid container spacing={5} rowGap={3}>
            {/* Bread crumbs */}
            <Grid item xs={12}>
              <BreadCrumps
                project={{
                  name: task?.projectId?.name,
                  id: task?.projectId?._id,
                }}
                category={{
                  name: task?.category,
                }}
                task={{
                  name: task?.name,
                  id: task?._id,
                }}
              />
            </Grid>
            {/* Left secton */}
            <Grid item lg={7} md={7} sm={12} xs={12}>
              <Grid container spacing={5} rowGap={1}>
                {/* details */}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      bgcolor: '#fff',
                      minHeight: '500px',
                      borderRadius: '12px',
                      height: '100%',
                    }}
                    padding={4}
                  >
                    <Stack direction="column" sx={{ width: '100%' }} spacing={5}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography
                          sx={{
                            color: '#2E2E2E',
                            fontFamily: 'Sora',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: 'normal',
                            letterSpacing: '-0.1px',
                          }}
                        >
                          {task?.name}
                        </Typography>
                        {manager && (
                          <MoreVert
                            sx={{
                              color: '#1C1B1F',
                              marginLeft: 'auto',
                              cursor: 'pointer',
                            }}
                            onClick={handleMenuOpen}
                          />
                        )}
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={() => setAnchorEl(null)}
                        >
                          <MenuItem
                            onClick={() => {
                              setEditMode(true);
                              setAnchorEl(null);
                            }}
                          >
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Edit />
                              <Typography sx={contentStyle}>Edit</Typography>
                            </Stack>
                          </MenuItem>
                        </Menu>
                      </Stack>
                      <Stack spacing={3}>
                        <Stack spacing={2} direction="row" alignItems={'center'}>
                          <Stack direction="row" spacing={1} alignItems={'center'} sx={{ width: '130px' }}>
                            <Iconify icon="nonicons:loading-16" />
                            <Typography sx={{ ...TypographyStyle2, fontWeight: 600 }}>Status:</Typography>
                          </Stack>
                          <Select
                            size="small"
                            value={task.status}
                            onChange={(e) => handleStatusChange(e)}
                            sx={{
                              // background: '#FFEDE3',
                              color: setColorByStatus(task.status),
                              '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none', // Remove the border
                              },
                            }}
                          >
                            {statusesAndPrint.map((status, key) => (
                              <MenuItem key={key} value={status.value} sx={{ color: setColorByStatus(status.value) }}>
                                {status.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </Stack>
                        <Stack spacing={2} direction="row" alignItems={'center'}>
                          <Stack direction="row" spacing={1} alignItems={'center'} sx={{ width: '130px' }}>
                            <CalendarToday
                              sx={{
                                fontSize: '16px',
                                color: '#63A3C0',
                              }}
                            />
                            <Typography sx={TypographyStyle1}>Start Date:</Typography>
                          </Stack>
                          {!editMode ? (
                            <Typography sx={TypographyStyle2}>
                              {task?.startDate ? moment(task.startDate).format('DD MMM YYYY') : 'NA'}
                            </Typography>
                          ) : (
                            <TextField
                              id="date"
                              type="date"
                              size="small"
                              defaultValue={startDate ? moment(startDate).format('YYYY-MM-DD') : ''}
                              onChange={(e) => setStartDate(e.target.value)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        </Stack>
                        <Stack spacing={2} direction="row" alignItems={'center'}>
                          <Stack direction="row" spacing={1} alignItems={'center'} sx={{ width: '130px' }}>
                            <CalendarToday
                              sx={{
                                fontSize: '16px',
                                color: '#63A3C0',
                              }}
                            />
                            <Typography sx={TypographyStyle1}>End Date:</Typography>
                          </Stack>
                          {!editMode ? (
                            <Typography sx={TypographyStyle2}>
                              {task?.endDate ? moment(task.endDate).format('DD MMM YYYY') : 'NA'}
                            </Typography>
                          ) : (
                            <TextField
                              id="date"
                              size="small"
                              type="date"
                              defaultValue={endDate ? moment(endDate).format('YYYY-MM-DD') : ''}
                              onChange={(e) => setEndDate(e.target.value)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        </Stack>
                        <Stack spacing={2} direction="row" alignItems={'center'}>
                          <Stack direction="row" spacing={1} alignItems={'center'} sx={{ width: '130px' }}>
                            <Category
                              sx={{
                                fontSize: '16px',
                                color: '#63A3C0',
                              }}
                            />
                            <Typography sx={TypographyStyle1}>Category:</Typography>
                          </Stack>
                          <Typography sx={TypographyStyle2}>{task.category}</Typography>
                        </Stack>
                      </Stack>
                      {!editMode && subTaskCountData?.total > 0 && (
                        <Stack direction="column" sx={{ width: '100%' }} spacing={2} pt={1}>
                          <Typography sx={{ ...TypographyStyle2, fontWeight: 600 }}>
                            Sub Tasks ({`${subTaskCountData?.completed}/${subTaskCountData?.total}`})
                          </Typography>
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            sx={{
                              width: '100%',
                            }}
                          >
                            <Box sx={{ width: '100%' }}>
                              <ProgressBar
                                completed={subTaskCountData?.progress || 0}
                                baseBgColor="#C0BEBE"
                                bgColor={subTaskCountData?.progress < 60 ? '#5297FF' : '#0ED652'}
                                maxCompleted={100}
                                height="10px"
                                width="100%"
                                customLabelStyles={{
                                  fontSize: '10px',
                                  color: `${subTaskCountData?.progress < 60 ? '#5297FF' : '#0ED652'}`,
                                }}
                              />
                            </Box>
                            <Typography
                              sx={{
                                color: '#000',
                                fontFamily: 'Work Sans',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                              }}
                            >
                              {subTaskCountData?.progress}%
                            </Typography>
                          </Stack>
                        </Stack>
                      )}
                      <Stack direction="column" sx={{ width: '100%' }} spacing={2} pt={1}>
                        <Typography sx={{ ...TypographyStyle2, fontWeight: 600 }}>Description</Typography>
                        {!editMode ? (
                          <Box
                            sx={{
                              width: '100%',
                              borderRadius: '6px',
                              // background: '#F5F5F5',
                            }}
                            padding={1.5}
                          >
                            <Typography
                              sx={{
                                color: '#414141',
                                fontFamily: 'Work Sans',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                letterSpacing: '-0.014px',
                              }}
                            >
                              {task.description}
                            </Typography>
                          </Box>
                        ) : (
                          <TextField
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            fullWidth
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        )}
                      </Stack>
                      {editMode && (
                        <Stack
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          sx={{ width: '100%' }}
                          spacing={2}
                        >
                          <Button
                            variant="outlined"
                            color="buttonone"
                            onClick={() => setEditMode(false)}
                            sx={{
                              borderWidth: '2px',
                              ':hover': {
                                borderWidth: '2px',
                              },
                              fontFamily: 'Sora',
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            color="buttonone"
                            onClick={updateTask}
                            sx={{
                              borderWidth: '2px',
                              ':hover': {
                                borderWidth: '2px',
                              },
                              fontFamily: 'Sora',
                            }}
                          >
                            Update
                          </Button>
                        </Stack>
                      )}
                    </Stack>
                  </Box>
                </Grid>
                {/* Sub tasks */}
                <Grid item xs={12}>
                  <SubTasks members={task?.members} taskId={taskId} projectId={projectId} manager={manager} />
                </Grid>
              </Grid>
            </Grid>
            {/* Right Section */}
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Grid container spacing={5} rowGap={1}>
                {/* Members */}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      bgcolor: '#fff',
                      borderRadius: '12px',
                    }}
                    padding={4}
                  >
                    <Stack direction="column" spacing={4} sx={{ width: '100%' }}>
                      <Stack direction="row" justifyContent="space-between" alignItems="start" sx={{ width: '100%' }}>
                        <Typography sx={{ ...headingStyle, color: '#0096DB' }}>Project Team</Typography>
                      </Stack>
                      <Stack direction="column" spacing={1.5} sx={{ width: '100%' }}>
                        <Typography sx={memberHeadingStyle}>Manager</Typography>
                        {task.members.map((member, i) => (
                          <>
                            {member.role === 'MANAGER' && (
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                                sx={{ cursor: 'pointer' }}
                                onClick={() => navigate(`/dashboard/member/${member.userId._id}`)}
                              >
                                <Tooltip TransitionComponent={Zoom} title={member.userId.email}>
                                  <Avatar alt={member.userId.fullname} src={member.userId.profilePic} />
                                </Tooltip>
                                <Typography sx={contentStyle}>{member.userId.fullname}</Typography>
                              </Stack>
                            )}
                          </>
                        ))}
                      </Stack>
                      <Stack direction="column" spacing={1.5} sx={{ width: '100%' }}>
                        <Stack
                          direction={'row'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          sx={{ width: '100%' }}
                        >
                          <Typography sx={memberHeadingStyle}>Members</Typography>
                          {manager && (
                            <Button
                              color="buttonone"
                              startIcon={<AddCircleOutlineSharpIcon />}
                              sx={{ fontFamily: 'Sora' }}
                              onClick={handleDialog}
                            >
                              Add
                            </Button>
                          )}
                        </Stack>
                        <Stack
                          direction="column"
                          spacing={1.5}
                          sx={{ width: '100%', maxHeight: '250px', overflowY: 'scroll' }}
                          className="scrollCard"
                        >
                          {task?.members.map((mdata, id) => (
                            <>
                              {mdata?.role !== 'MANAGER' && (
                                <Stack
                                  direction={'row'}
                                  key={id}
                                  alignItems={'center'}
                                  justifyContent={'space-between'}
                                  sx={{
                                    width: '100%',
                                    background: '#fff',
                                    ':hover': {
                                      background: '#f8f8f8',
                                    },
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => navigate(`/dashboard/member/${mdata.userId._id}`)}
                                  >
                                    <Tooltip TransitionComponent={Zoom} title={mdata.userId.email}>
                                      <Badge
                                        invisible={!mdata.userId.isBlocked}
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        badgeContent={
                                          <Icon fontSize="20px" icon="material-symbols:block" color="red" />
                                        }
                                      >
                                        <Avatar alt="Remy Sharp" src={mdata.userId.profilePic}>
                                          {mdata.userId.fullname.charAt(0)}
                                        </Avatar>
                                      </Badge>
                                    </Tooltip>
                                    <Typography sx={contentStyle}>{mdata.userId.fullname}</Typography>
                                  </Stack>
                                  {manager && (
                                    <IconButton onClick={() => handleDeletePopup(mdata.userId._id)} color="buttonone">
                                      <Iconify icon="material-symbols:delete" />
                                    </IconButton>
                                  )}
                                </Stack>
                              )}
                            </>
                          ))}
                        </Stack>
                      </Stack>
                    </Stack>
                  </Box>
                </Grid>
                {/* Attachments */}
                <Grid item xs={12}>
                  <TaskAttachments attachments={attachments} taskId={taskId} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
};
