import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

// material
import { Container, Stack, Typography, CircularProgress, Button, Grid, TablePagination, Chip } from '@mui/material';
import { Clear } from '@mui/icons-material';

// components
import Page from '../components/Page';
import Filter from '../components/filters/ProjectFilter';
import Iconify from '../components/Iconify';

import ProjectCard from '../components/ProjectCard';

// ----------------------------------------------------------------------

export default function Project() {
  const size = 12;
  const [searchParams, setSearchParams] = useSearchParams();
  const category = searchParams.get('category');
  const [projects, setProjects] = useState([]);
  const [filters, setFilters] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [pages, setPages] = useState(0);
  const [projectCount, setProjectCount] = useState(0);
  const [options, setOptions] = useState({ size, page: 0, category });
  const [filterOpen, setFilterOpen] = useState(false);

  const getProject = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/project/user/all`, {
        params: options,
      })
      .then((res) => {
        if (!res.data.isError) {
          setProjects(res.data.data.projects || []);
          setProjectCount(res.data.data.maxRecords || 0);
          setIsLoading(false);
        }
        console.log(projectCount);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const clearFilters = () => {
    setFilters([]);
    setOptions({ size, page: 0 });
    getProject({ size, page: 0 });
  };

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    const temp = { ...options, page: newPage };
    setOptions(temp);
    setIsLoading(true);
    getProject(temp);
  };
  const handleDelete = (data) => {
    const selected = data[0];
    setFilters(filters.filter((e) => e !== data));
    setOptions((current) => {
      const copy = { ...current };
      delete copy[selected];
      return copy;
    });
    getProject(options);
  };
  const applyFilters = (filter) => {
    const temp = { page: 0, size };
    console.log(filter);
    if (filter.name) {
      temp.name = filter.name.trim();
    }
    if (filter.location) {
      temp.location = filter.location.trim();
    }
    if (filter.category && filter.category !== 'ALL') {
      temp.category = filter.category.trim();
    }

    if (filter.startDate) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate;
    }
    setOptions(temp);
    let filterEntries = Object.entries(filter);
    filterEntries = filterEntries.filter((item) => item[1] !== '');
    setFilters(filterEntries);
    console.log(filterEntries);
    setPages(0);
    getProject(temp);
    console.log(filters);
  };

  useEffect(() => {
    getProject(options);
  }, [options]);

  useEffect(() => {
    if (category) {
      setFilters([['category', category]]);
    }
  }, [category]);

  const projectColors = ['#f0f2f7', '#f0f2f7', '#f0f2f7', '#f0f2f7'];
  return (
    <Page title="Dashboard: Projects">
      {isloading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container>
          <Grid alignItems="center" container rowGap={3} spacing={3}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems={'center'}
                sx={{
                  marginBottom: '-20px',
                }}
              >
                <Typography
                  sx={{
                    color: '#0096DB',
                    fontFamily: 'Sora',
                    fontSize: '22px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    letterSpacing: '-0.11px',
                  }}
                >
                  Projects
                </Typography>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Button
                    color="buttonone"
                    variant="contained"
                    onClick={() => setFilterOpen(!filterOpen)}
                    startIcon={<Iconify icon="ic:round-filter-list" />}
                  >
                    Filter
                  </Button>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Filter applyFilters={applyFilters} open={filterOpen} setOpen={setFilterOpen} filterValues={options} />
            </Grid>
            {filters.length > 0 && (
              <Grid item xs={12}>
                <Grid direction="row" spacing={1} flex="wrap" paddingTop={1}>
                  {filters.map((item, key) => (
                    <Chip key={key} label={`${item[0]} : ${item[1]}`} onDelete={() => handleDelete(item)} />
                  ))}
                  {!!filters.length && (
                    <Button size="small" onClick={clearFilters} startIcon={<Clear />}>
                      Clear Filters
                    </Button>
                  )}
                </Grid>{' '}
              </Grid>
            )}

            {projects?.map((item, id) => (
              <Grid key={id} item sm={6} xs={12} md={4}>
                <ProjectCard project={item} />
              </Grid>
            ))}
            <Stack sx={{ width: '100%' }}>
              <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={projectCount}
                page={pages}
                onPageChange={handlePageChange}
                rowsPerPage={size}
                sx={{
                  '& .MuiTablePagination-displayedRows': {
                    color: '#2E2E2E',
                    fontFamily: 'Sora',
                    fontSize: '16px',
                    fontWeight: 400,
                  },
                }}
              />
            </Stack>
          </Grid>
        </Container>
      )}
    </Page>
  );
}
