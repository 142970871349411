import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';

const FileInputField = ({ onChange, value, accept }) => {
  const [fileName, setFileName] = useState('');

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFileName(selectedFile ? selectedFile.name : '');
    onChange(e);
  };

  const handleButtonClick = () => {
    document.getElementById('fileInput').click();
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <TextField
        fullWidth
        size="small"
        value={fileName}
        InputProps={{
          style: { pointerEvents: 'none' },
        }}
        disabled
      />
      <Button
        variant="contained"
        color="buttonone"
        onClick={handleButtonClick}
        sx={{
          marginLeft: '-10px',
          height: '40px',
          borderRadius: '0px 10px 10px 0px',
        }}
      >
        Browse
      </Button>
      <input type="file" accept={accept} id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} />
    </Box>
  );
};

export default FileInputField;
