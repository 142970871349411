import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import loginReducer from '../sections/auth/login/LoginSlice';
import taskMessagesReducer from '../redux/taskMessagesSlice';
import sidebarCollapseReducer from '../redux/sidebarCollapseSlice';

const reducers = combineReducers({
  login: loginReducer,
  taskMessages: taskMessagesReducer,
  sidebarCollapse: sidebarCollapseReducer,
});
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['login', 'taskMessages', 'sidebarCollapse'],
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export default store;
