// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const topConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('ic:round-dashboard'),
  },

  {
    title: 'project',
    path: '/dashboard/project',
    icon: getIcon('ant-design:project-filled'),
  },
  {
    title: 'tasks',
    path: '/dashboard/to-do',
    icon: getIcon('fluent:tasks-app-28-filled'),
  },
];

const bottomConfig = [];

export { topConfig, bottomConfig };
