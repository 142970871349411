import { useState } from 'react';
import Calendar from 'react-calendar';
import './Calendar.css';
import { Box } from '@mui/material';

function CalendarCard() {
  const [value, onChange] = useState(new Date());

  return (
    <Box sx={{ width: '100%', background: '#fff', borderRadius: '12px', padding: 1}}>
      <Calendar onChange={onChange} value={value} />
    </Box>
  );
}

export default CalendarCard;
