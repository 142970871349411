import { faker } from '@faker-js/faker';
import { useEffect, useState, useRef } from 'react';

// @mui
import { useTheme, styled } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  CircularProgress,
  Card,
  CardHeader,
  Box,
  OutlinedInput,
  InputAdornment,
  Stack,
} from '@mui/material';
import axios from 'axios';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';

// sections
import {} from '../sections/@dashboard/app';
import CategoryCard from '../components/CategoryCard';
import CalendarCard from '../components/CalendarCard';
import TasksSummaryTable from '../components/TasksSummaryTable';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  const getCategories = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/category/user/projects`, {});
      console.log(res);
      setCategories(res.data.data.categories);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <Page title="Dashboard">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',

              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Container maxWidth="xl">
            <Grid container spacing={5}>
              <Grid item xs={12} sm={12} md={6} lg={7}>
                <Typography
                  sx={{
                    color: '#0B132B',
                    marginBottom: '26px',
                  }}
                  variant="headMain"
                  component={'p'}
                >
                  Categories
                </Typography>
                <Grid container spacing={3}>
                  {categories.map((category, id) => (
                    <Grid key={id} item xs={6} md={6} lg={4}>
                      <CategoryCard category={category} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <Box sx={{ width: '100%' }} display={'flex'} justifyContent={'center'}>
                  <CalendarCard />
                </Box>
              </Grid>

              {/* <Grid item xs={12} md={12} lg={12}>
                <Graph data={categories} />
              </Grid> */}
              <Grid item xs={12}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <TasksSummaryTable />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        )}
      </Page>
    </>
  );
}
