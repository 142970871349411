export const ACTION_TYPES = {
  FETCH_FIRST_START: 'FETCH_FIRST_START',
  FETCH_FIRST_SUCCESS: 'FETCH_FIRST_SUCCESS',
  FETCH_FIRST_ERROR: 'FETCH_FIRST_ERROR',
  FETCH_MORE_START: 'FETCH_MORE_START',
  FETCH_MORE_SUCCESS: 'FETCH_MORE_SUCCESS',
  FETCH_MORE_ERROR: 'FETCH_MORE_ERROR',
  FETCH_NEW_START: 'FETCH_NEW_START',
  FETCH_NEW_SUCCESS: 'FETCH_NEW_SUCCESS',
  FETCH_NEW_ERROR: 'FETCH_NEW_ERROR',
  SEND_START: 'SEND_START',
  SEND_SUCCESS: 'SEND_SUCCESS',
  SEND_ERROR: 'SEND_ERROR',
  VIEW_NEW_MESSAGES: 'VIEW_NEW_MESSAGES',
};
