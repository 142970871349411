import { MoreHoriz, MoreVert } from '@mui/icons-material';
import { Avatar, AvatarGroup, Badge, Box, Icon, Stack, Tooltip, Typography } from '@mui/material';
import ProgressBar from '@ramonak/react-progress-bar';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Iconify from './Iconify';

function SubTaskCard({ subTask }) {
  const [searchParams] = useSearchParams();
  const taskId = searchParams.get('taskId');
  const projectId = searchParams.get('projectId');

  const navigate = useNavigate();
  return (
    <Stack 
      direction={'column'}
      spacing={2}
      sx={{
        borderRadius: '6px',
        padding: 3,
        cursor: 'pointer',
        backgroundColor: '#fff',
        width: '100%',
        height: '100%',
      }}
      onClick={() =>
        navigate(
          `/dashboard/project/task/subtask/view?projectId=${projectId}&taskId=${taskId}&subTaskId=${subTask?._id}`
        )
      }
    >
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar alt="" src={subTask?.userData?.profilePic}>
            {subTask?.userData?.fullname?.charAt(0).toUpperCase()}
          </Avatar>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap', 
              fontWeight: 600,
              fontSize: '16px',
              color: '#2E2E2E',
              fontFamily: 'Sora , sans-serif',
            }}
          >
            {subTask?.userData?.fullname}
          </Typography>
        </Stack>
      </Stack>
      <Box>
        <Typography
          sx={{
            width: '100%',
            fontWeight: 400,
            fontSize: '14px',
            color: '#2e2e2e',
            fontFamily: 'Sora , sans-serif',
          }}
        >
          {subTask?.name}
        </Typography>
      </Box>

      <Stack direction="column" spacing={1}>
        <Typography
          sx={{
            color: '#2E2E2E',
            fontFamily: 'Work Sans',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '5px',
          }}
        >
          Progress
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            width: '100%',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <ProgressBar
              completed={subTask?.progress}
              baseBgColor="#C0BEBE"
              bgColor={subTask?.progress < 60 ? '#5297FF' : '#0ED652'}
              maxCompleted={100}
              height="10px"
              width="100%"
              customLabelStyles={{
                fontSize: '10px',
                color: `${subTask?.progress < 60 ? '#5297FF' : '#0ED652'}`,
              }}
            />
          </Box>
          <Typography
            sx={{
              color: '#000',
              fontFamily: 'Work Sans',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 500,
            }}
          >
            {subTask.progress}%
          </Typography>
        </Stack>
        <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'flex-start'} pt={2}>
          <Iconify icon={'mingcute:attachment-line'} color={'#2E2E2E'} />
          <Typography
            sx={{
              fontFamily: 'Work Sans',
              fontSize: '14px',
              fontWeight: 500,
              color: '#6d6d6d',
            }}
          >
            {`${subTask?.attachmentsCount} Files`}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default SubTaskCard;
