import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  TextField,
  Divider,
} from '@mui/material';
import Moment from 'moment';
import ProgressBar from '@ramonak/react-progress-bar';
import { Box } from '@mui/system';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// components
import { EditRounded, RemoveRedEyeRounded } from '@mui/icons-material';
import axios from 'axios';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Filter from '../components/filters/To-DoFilter';
// mock


// ----------------------------------------------------------------------

const commonHeadStyle = {
  color: '#0D4CA1',
  fontFamily: 'Sora, sans-serif',
  fontSize: '16px',
  fontWeight: 400,
};
const commonBodyStyle = {
  color: '#000',
  fontFamily: 'Sora',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
};

// const customToolBar = ({setFilter})
export default function ToDo() { 
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [pages, setPages] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const size = 10;
  const [options, setOptions] = useState({ size, page: 0 });
  const [input, setInput] = useState(1);
  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    getToDos(options);
  }, []);

  const getToDos = (options) => {
    console.log(options);
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/subTask/user/all`, {
        params: options,
      })
      .then((res) => {
        console.log('ressssssssssss', res);
        if (res.data.isError === false) {
          setUserCount(res.data.data.maxRecords);
          setUserData(res.data.data.records);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    const temp = { ...options, page: newPage };
    setOptions(temp);
    getToDos(temp);
  };

  const applyFilters = (filter) => {
    const temp = { page: 0, size };
    if (filter.name) {
      temp.name = filter.name;
    }
    if (filter.userId) {
      temp.userId = filter.userId;
    }
    if (filter.projectId) {
      temp.projectId = filter.projectId;
    }
    if (filter.dueDate) {
      temp.dueDate = filter.dueDate;
    }
    setOptions(temp);
    setPages(0);
    getToDos(temp);
  };

  const goToPage = () => {
    if (input > Math.ceil(userCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getToDos(temp);
  };

  return (
    <>
      <Page title="Taks">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <ToastContainer />
            <Container maxWidth="xl">
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography
                  sx={{
                    color: '#0B132B',
                    lineHeight: 'normal',
                    letterSpacing: '-0.1px',
                  }}
                  variant="headMain"
                  component={'p'}
                >
                  Tasks
                </Typography>
              </Stack>
              <Card>
                <Stack direction="row" spacing={2} padding={2} justifyContent={'space-between'}>
                  <Stack direction="row" spacing={1.5}>
                    <TextField
                      size="small"
                      style={{ maxWidth: '100px' }}
                      type="number"
                      component="div"
                      label="Go to"
                      value={input}
                      onInput={(e) => setInput(e.target.value)}
                    />
                    <Button
                      variant="contained"
                      onClick={goToPage}
                      sx={{
                        backgroundColor: '#0096DB',
                        height: '40px',
                      }}
                    >
                      Go
                    </Button>
                  </Stack>
                  <Button
                    color="buttonone"
                    variant="contained"
                    onClick={() => setFilterOpen(!filterOpen)}
                    startIcon={<Iconify icon="ic:round-filter-list" />}
                  >
                    Filter
                  </Button>
                </Stack>
                <Filter applyFilters={applyFilters} open={filterOpen} setOpen={setFilterOpen} filterValues={options} />
                <Divider
                  sx={{
                    background: ' rgba(0, 0, 0, 0.50)',
                    marginBottom: '24px',
                  }}
                />
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      {/* <UserListHead /> */}
                      <TableHead>
                        <TableRow>
                          <TableCell sx={commonHeadStyle}>Sl No</TableCell>
                          <TableCell sx={commonHeadStyle}>Name</TableCell>
                          <TableCell sx={commonHeadStyle}>Assigend To</TableCell>
                          <TableCell sx={commonHeadStyle}>Task Name</TableCell>
                          <TableCell sx={commonHeadStyle}>Project Name</TableCell>
                          <TableCell sx={commonHeadStyle}>Progress</TableCell>
                          <TableCell sx={commonHeadStyle}>Upadated On</TableCell>
                          <TableCell sx={commonHeadStyle}>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userData?.map((item, id) => (
                          <TableRow key={id}>
                            <TableCell sx={{ ...commonBodyStyle, width: '60px' }}>{pages * size + (id + 1)}</TableCell>
                            <TableCell sx={commonBodyStyle}>{item.name}</TableCell>
                            <TableCell sx={commonBodyStyle}>{item?.userData?.fullname}</TableCell>
                            <TableCell sx={{ commonBodyStyle }}>{item?.taskData?.name}</TableCell>
                            <TableCell sx={commonBodyStyle}>{item?.projectData?.name}</TableCell>
                            <TableCell sx={commonBodyStyle}>
                              <ProgressBar
                                completed={item?.progress}
                                baseBgColor="#C0BEBE"
                                bgColor={item?.progress < 60 ? '#5297FF' : '#0ED652'}
                                maxCompleted={100}
                                height="14px"
                                labelAlignment="inside"
                                width="100%"
                                customLabelStyles={{
                                  fontSize: '10px',
                                }}
                              />
                            </TableCell>
                            <TableCell sx={commonBodyStyle}> {Moment(item.updatedAt).format('DD-MM-YYYY')}</TableCell>
                            <TableCell align="center">
                              <Stack direction="row" spacing={1}>
                                <Box
                                  sx={{
                                    cursor: 'pointer',
                                    background: '#52D0B3',
                                    '&:hover': {
                                      background: '#42a78f',
                                    },
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '7px',
                                    borderRadius: '10px',
                                  }}
                                  onClick={() =>
                                    navigate(
                                      `/dashboard/project/task/subtask/view?projectId=${item.projectData?._id}&taskId=${item.taskData?._id}&subTaskId=${item._id}`
                                    )
                                  }
                                >
                                  <RemoveRedEyeRounded sx={{ color: '#fff', fontSize: '18px' }} />
                                </Box>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component={'div'}
                  count={userCount}
                  onPageChange={handlePageChange}
                  rowsPerPage={rowsPerPage}
                  page={pages}
                  showFirstButton
                  showLastButton
                />
              </Card>
            </Container>
          </>
        )}
      </Page>
    </>
  );
}
