import { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Moment from 'moment';
import axios from 'axios';
import {
  Container,
  Stack,
  Typography,
  CircularProgress,
  Card,
  Grid,
  Box,
  CardContent,
  AvatarGroup,
  Avatar,
  Divider,
  Tabs,
  Tab,
  TablePagination,
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PeopleSharpIcon from '@mui/icons-material/PeopleSharp';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FlagCircleRoundedIcon from '@mui/icons-material/FlagCircleRounded';
import CategoryIcon from '@mui/icons-material/Category';

// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function Task() {
  const location = useLocation();
  const path = location.pathname?.split('/')[3];
  const [task, setTask] = useState({});
  const [isloading, setIsLoading] = useState(true);
  const [taskCheck, setTaskCheck] = useState(0);

  const getTask = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/task/user/`, {
        params: { id: path },
      })
      .then((res) => {
        if (res.data.message === 'Task successfully retrieved') {
          setTask(res.data.data.task || []);

          setTaskCheck(Object.keys(task).length);
          setIsLoading(false);
          console.log(task);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTask();
    console.log(taskCheck);
  }, []);

  return (
    <Page title="Dashboard: Task">
      {isloading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container>
          {' '}
          <Typography variant="h4" sx={{ mb: 5 }}>
            {task.name.toUpperCase()}
          </Typography>
          <Card>
            {' '}
            <Grid
              style={{ padding: '5px' }}
              container
              spacing={1}
              sx={12}
              // direction='row'
              // </Box>justify='flex-start'
              // alignItem='flex-start'
            >
              <Grid item xs={12}>
                <Card sx={{ bgcolor: '#e0f7fa' }} style={{ minHeight: '250px', height: '250px' }}>
                  <CardContent>
                    {' '}
                    <Stack direction={'row'} paddingTop="30px" paddingBottom="">
                      <Stack direction={'column'}>
                        {' '}
                        <Stack direction={'row'}>
                          {' '}
                          <FlagCircleRoundedIcon />
                          <Typography variant="h6">Start Date</Typography>
                        </Stack>
                        <Typography variant="h7">{Moment(task.startDate).format('DD/MMM/YYYY')}</Typography>
                      </Stack>
                      <Stack direction={'column'} marginLeft="6%">
                        {' '}
                        <Stack direction={'row'}>
                          {' '}
                          <AssignmentTurnedInIcon />
                          <Typography variant="h6">End Date</Typography>
                        </Stack>
                        <Typography variant="h7">{Moment(task.endDate).format('DD/MMM/YYYY')}</Typography>
                      </Stack>
                      <Stack direction={'column'} marginLeft="6%">
                        <Stack direction={'row'} marginLeft="6%">
                          <AttachFileIcon />
                          <Typography variant="h6">Files</Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack direction={'row'} paddingTop="30px" paddingBottom="">
                      <Stack direction={'row'}>
                        {' '}
                        <PeopleSharpIcon />
                        <Typography variant="h6">Members</Typography>
                      </Stack>
                    </Stack>{' '}
                    <Stack direction={'row'} paddingBottom="">
                      <Grid style={{ padding: '5px' }} container spacing={1} sx={12}>
                        {task.members.map((item, id) => {
                          return (
                            <Grid item xs={1.5}>
                              <Typography variant="h7" key={id}>
                                {item.fullname?.toUpperCase()}
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Container>
      )}
    </Page>
  );
}
