import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isAuth: false,
  user: JSON.parse(localStorage.getItem('profile')) || null,
  error: '',
};

const LoginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginPending: (state) => {
      state.isLoading = true;
    },

    loginSuccess: (state, action) => {
      state.isLoading = false;
      state.isAuth = true;
      state.error = '';
      state.user = action.payload;
    },

    loginFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.user = null;
    },

    logOut: (state) => {
      state.isLoading = false;
      state.error = null;
      state.user = null;
    },
  },
});

const { reducer, actions } = LoginSlice;
export const { loginFail, loginPending, loginSuccess } = actions;
export default reducer;
