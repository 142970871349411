export const commonHeadStyle = {
  color: '#0D4CA1',
  fontFamily: 'Sora, sans-serif !important',
  fontSize: '16px !important',
  fontWeight: '600 !important',
};

export const commonBodyStyle = {
  color: '#000',
  fontFamily: 'Sora !important',
  fontSize: '14px !important',
  fontStyle: 'normal !important',
  fontWeight: '400 !important',
};

export const boxStyle = {
  borderRadius: '12px',
  backgroundColor: '#FFFFFF',
  padding: 5,
  height: '100%',
};
