import * as React from 'react';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { TextField, Button, Stack, Box, MenuItem, Collapse, Grid } from '@mui/material';

const Filter = ({ open, filterValues, applyFilters }) => (
  <Collapse in={open}>
    <Formik
      initialValues={{
        name: filterValues.name || '',
        dueDate: filterValues.dueDate ? moment(filterValues.dueDate).format('YYYY-MM-DD') : '',
        projectId: filterValues.projectId || '',
      }}
      onSubmit={async (values) => {
        applyFilters(values);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Form style={{ padding: '10px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}> 
              <TextField fullWidth name="name" id="name" label="Name" value={values.name} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                id="projectId"
                name="projectId"
                label="Project Id"
                value={values.projectId}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                type="date"
                fullWidth
                id="dueDate"
                name="dueDate"
                label="Due Date"
                value={values.dueDate}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button variant="contained" type="submit" style={{ marginTop: '10px' }} color="buttonone">
                Apply
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  </Collapse>
);

export default Filter;
